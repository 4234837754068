import React, { useEffect, useState, useRef } from 'react';
import close from '../asset/img/ic/close.svg';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import video from '../asset/movie/metaverse.mp4';
import { useDispatch } from 'react-redux';
import { getLogout } from '../store/slices/user';

const { REACT_APP_META_VERSE_URL } = process.env;

const Metaverse = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:768px)');
  const [onMeta, setOnMeta] = useState(false);
  const [iframeSrc, setIframeSrc] = useState(null);
  const iframeRef = useRef(null);

  useEffect(() => {
    if (!isMobile) {
      setOnMeta(true);
    }
  }, [isMobile]);

  useEffect(() => {
    if (onMeta) {
      setIframeSrc(REACT_APP_META_VERSE_URL);
    } else {
      setIframeSrc(null);
    }
  }, [onMeta]);

  const sendToken = () => {
    if (iframeRef.current) {
      const accessToken = window.localStorage.getItem('accessToken');
      iframeRef.current.contentWindow.postMessage(
        { type: 'accessToken', accessToken },
        REACT_APP_META_VERSE_URL
      );
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      if (event.data.type === 'logout_now') {
        dispatch(getLogout());
      }
      if (event.data.type === 'LOGOUT') {
        dispatch(getLogout());
      }
    };

    window.addEventListener('message', handleMessage);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [dispatch]);

  return (
    <div>
      {isMobile ? (
        <>
          <div className="flex justify-end mt-4 mr-4">
            <img src={close} alt="닫기이미지" onClick={() => navigate(-1)} />
          </div>
          <div className="mt-14 mb-5 text-18 text-center">
            메타버스는 PC에서만 입장이 가능합니다.
          </div>
          <video class="video" autoPlay muted>
            <source type="video/mp4" src={video} />
          </video>
        </>
      ) : (
        <>
          {iframeSrc && (
            <iframe
              ref={iframeRef}
              title="Content"
              width="100%"
              height="1000px"
              src={iframeSrc}
              allowFullScreen="true"
              onLoad={sendToken}></iframe>
          )}
        </>
      )}
    </div>
  );
};

export default Metaverse;
