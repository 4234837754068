import './scheduler.css';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Calendar from 'react-calendar';
import moment from 'moment';
import HD from 'date-holidays';
import { isEmpty } from 'lodash';
// 컴포넌트
import { BlueBox, LayoutBgGray, Tag, WhiteBox } from '../component/Styled';
import BackHeader from '../component/BackHeader';
import { getEvents, getDayEvents } from '../store/slices/schedule';
import BasicModal from '../component/Modal';
// utils
import { fDate, fDay, fDateTime } from '../utils/formatTime';
import { statusSchedules } from '../utils/statusCode';

const Scheduler = () => {
  const { events, dates, dayEvents, error } = useSelector(
    (state) => state.schedule
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const rememberDate = localStorage.getItem('rememberDate');
  const [selectedDate, setSelectedDate] = useState(rememberDate ?? new Date());

  const handleDateClick = (date) => {
    // 클릭한 날짜 데이터를 받음
    setSelectedDate(date);
    dispatch(getDayEvents(fDate(date)));
    localStorage.setItem('rememberDate', date);
  };

  const hd = new HD();
  hd.init('KR');

  useEffect(() => {
    dispatch(getEvents());
    setTimeout(() => dispatch(getDayEvents(fDate(selectedDate))), 500);
  }, []);

  return (
    <LayoutBgGray>
      <BackHeader ment={'일정관리'} url={'/main'} />
      <BasicModal msg={error?.response?.message} />

      <Calendar
        calendarType="gregory"
        onClickDay={handleDateClick}
        formatDay={(locale, date) => moment(date).format('D')}
        tileClassName={({ date, view }) => {
          const isHoliday = hd.isHoliday(new Date(date));
          const dayOfWeek = moment(date).day();

          // 해당 날짜가 공휴일인지 확인하고, 토요일은 'saturday-marker', 일요일은 'sunday-marker' 클래스를 반환
          return `${isHoliday ? 'holiday-marker ' : ''}${
            dayOfWeek === 6 && 'saturday-marker'
          }`;
        }}
        tileContent={({ date, view }) => {
          const formattedDate = moment(date).format('YYYY-MM-DD');
          const isMarked = dates?.includes(formattedDate);

          if (isMarked) {
            return (
              <div className="flex justify-center items-center absolute top-2 right-2">
                <div className="dot"></div>
              </div>
            );
          } else {
            return null;
          }
        }}
      />

      <BlueBox>
        <div className="text-18">{fDay(selectedDate)}</div>
        {isEmpty(dayEvents) ? (
          <WhiteBox>
            <div className="text-center mt-5">일정이 없습니다.</div>
          </WhiteBox>
        ) : (
          dayEvents?.map((el, idx) => {
            return (
              <WhiteBox
                key={idx}
                onClick={() =>
                  navigate(`/schedulerDetail/${el?._id}`, {
                    state: { data: el, mentor: events[el?._id]?.mentor }
                  })
                }>
                {/* //활동일지 추가하기 */}
                <div className="flex gap-2">
                  <Tag
                    color={statusSchedules[el?.status].color}
                    textColor={statusSchedules[el?.status].textColor}
                    className="text-main2">
                    {statusSchedules[el?.status].text}
                  </Tag>
                  {/* 활동일지가 1개 이상 */}
                  {events[el?._id]?.activities.some(
                    (activity) => activity?.journals.length !== 0
                  ) &&
                    el?.category?.title === '직장체험' && (
                      <Tag>활동일지 ({events[el?._id]?.journalCount})</Tag>
                    )}
                  {/*  작성/후기 코멘트 */}
                  {events[el?._id]?.review?.comment && <Tag>후기 코멘트</Tag>}

                  {el?.category?.title === '직장체험' &&
                    events[el?._id]?.activities?.some((activity) => {
                      return activity?.journals.some(
                        (journal) => journal?.comment !== null
                      );
                    }) && <Tag key={idx}>일지 코멘트</Tag>}
                </div>
                <div className="flex justify-between mt-2 px-1">
                  <div>
                    <div className="text-12 text-text3">{el?.cateogry}</div>
                    <div className="font-medium text-text1 text-13 overflow-hidden text-ellipsis">
                      {el?.title}
                    </div>
                  </div>
                  <div>
                    <div className="text-11 text-text8 font-medium text-left">
                      {fDateTime(el?.startDate)} 시작
                    </div>
                    <div className="text-11 text-text8 font-medium text-left">
                      {fDateTime(el?.endDate)} 종료
                    </div>
                  </div>
                </div>
              </WhiteBox>
            );
          })
        )}
      </BlueBox>
    </LayoutBgGray>
  );
};

export default Scheduler;
