import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getSelfPlans } from '../store/slices/selfPlan';
import {
  BlueBox,
  Card,
  CommentTag,
  LayoutBgGray,
  WriteButton
} from '../component/Styled';
import BackHeader from '../component/BackHeader';
import plus from '../asset/img/ic/plus.webp';
import { fDate } from '../utils/formatTime';
import { isEmpty } from 'lodash';
import usePaging from '../hooks/usePaging';
import { Pagination } from '@mui/material';

const SelfPlan = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page, handleChangePage, itemsPerPage } = usePaging(1);
  const { selfPlans } = useSelector((state) => state.selfPlan);

  useEffect(() => {
    dispatch(getSelfPlans());
    // 페이지 초기화용
    localStorage.setItem('pageNumber', 1);
  }, []);

  return (
    <LayoutBgGray>
      <BackHeader ment={'자립계획서'} url="/main" />
      <Card className="px-4 py-[18px]" style={{ alignItems: 'center' }}>
        <WriteButton
          height="56px"
          width="100%"
          onClick={() => navigate('/selfPlanWrite')}>
          <div className="mr-2">
            <img src={plus} alt="플러스" />
          </div>
          {selfPlans.length === 0 ? (
            <div className="pt-0.5 text-text4">작성하러가기</div>
          ) : (
            <div className="pt-0.5 text-text4">추가작성</div>
          )}
        </WriteButton>
        {selfPlans.map((plan, _idx) => {
          return (
            <BlueBox
              key={_idx}
              onClick={() =>
                navigate(`/selfPlanDetail/${plan?._id}`, { state: plan })
              }>
              <div className="font-bold text-text3 mb-2">{plan?.goal}</div>

              <div className="items-baseline justify-between">
                {!isEmpty(plan?.comment?.content) && (
                  <CommentTag color="#fff">{plan?.comment?.content}</CommentTag>
                )}
                <div className="text-14 text-text3 text-left">
                  {fDate(plan?.writeAt)}
                </div>
              </div>
            </BlueBox>
          );
        })}
        <Pagination
          count={Math.ceil(selfPlans.length / itemsPerPage)}
          page={Number(page)}
          onChange={handleChangePage}
          color="primary"
        />
      </Card>
    </LayoutBgGray>
  );
};

export default SelfPlan;
