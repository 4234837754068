import React from 'react';
import loading from '../asset/img/loading.json';
import Lottie from 'react-lottie-player';

const Loading = () => {
  return (
    <>
      <Lottie loop animationData={loading} play speed={10} />
      <div className="text-center">메인화면으로 이동중입니다.</div>
    </>
  );
};

export default Loading;
