import React from 'react';
import { TopBox } from './Styled';
import arrow from '../asset/img/ic/arrow.svg';
import { fDate } from '../utils/formatTime';

const CenterBox = ({ data, onClick }) => {
  return (
    <TopBox className="flex flex-col gap-2 mb-2" onClick={onClick}>
      <div className="text-18 text-text1 font-bold">
        {data?.organizationName}
      </div>
      <div className="text-text2 font-semibold">{data?.subtitle}</div>
      <div className="inline-flex flex-row justify-between">
        <div className="text-14 text-text5 font-normal mt-[3px]">
          {fDate(data?.createdAt)}
        </div>
        <div>
          <img src={arrow} alt="화살표" />
        </div>
      </div>
    </TopBox>
  );
};

export default CenterBox;
