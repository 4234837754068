import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { checkTokenAndRefresh } from '../utils/function';
import { isValidToken } from '../utils/jwt';

const AuthLayOut = () => {
  const { isLogin } = useSelector((state) => state.user);
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const remember = localStorage.getItem('remember');

    if (remember) {
      const tokenResult = isValidToken(accessToken);
      if (!tokenResult) {
        checkTokenAndRefresh(dispatch);
      }
    } else {
      const tokenResult = isValidToken(accessToken);
      if (tokenResult === false) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('remember');
        localStorage.removeItem('pageNumber');
        navigate('/');
      }
    }
  }, []);

  useEffect(() => {
    if (isLogin && accessToken) {
      navigate('/main');
    } else {
      navigate('/login', { state: pathname });
    }
  }, [isLogin, accessToken]);

  return (
    <div>
      <Outlet />
    </div>
  );
};

export default AuthLayOut;
