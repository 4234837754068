import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMentors, getMentorCategory } from '../store/slices/ilharu';
import { BlueBox, LayoutBgGray } from '../component/Styled';
import BackHeader from '../component/BackHeader';
import Mentor from '../component/Mentor';

const MentorList = () => {
  const dispatch = useDispatch();
  const { mentors, categories } = useSelector((state) => state.ilharu);
  const cate = [{ title: '전체' }, ...categories];
  const [selectedCate, setSelectedCate] = useState('전체');
  const [clicked, setClicked] = useState(0);
  const filterMentors = mentors.filter(
    (mentor) => mentor?.category?.title === selectedCate
  );
  const handleClick = (idx, title) => {
    setClicked(idx);
    setSelectedCate(title);
  };

  useEffect(() => {
    dispatch(getMentors());
    dispatch(getMentorCategory());
  }, []);

  return (
    <LayoutBgGray>
      <BackHeader ment={'일하루 멘토 보기'} />
      <BlueBox>
        <div className="flex gap-3 mb-4">
          {cate.map((el, idx) => (
            <div
              key={idx}
              style={{ fontWeight: clicked === idx && 700 }}
              onClick={() => handleClick(idx, el?.title)}>
              {el?.title}
            </div>
          ))}
        </div>
        {selectedCate === '전체'
          ? mentors.map((el, idx) => <Mentor key={idx} data={el} />)
          : filterMentors.map((el, idx) => <Mentor key={idx} data={el} />)}
      </BlueBox>
    </LayoutBgGray>
  );
};

export default MentorList;
