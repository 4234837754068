import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LayoutBgGray, TopBox } from '../component/Styled';
import BackHeader from '../component/BackHeader';
import arrow from '../asset/img/ic/arrow.svg';
const Mypage = () => {
  const navigate = useNavigate();
  return (
    <LayoutBgGray>
      <BackHeader ment={'정보수정'} />
      <TopBox
        className="flex justify-between mb-2.5 cursor-pointer"
        onClick={() => navigate('/profile')}>
        <div>프로필 정보 변경</div>
        <div>
          <img src={arrow} alt="화살표" />
        </div>
      </TopBox>
      <TopBox
        className="flex justify-between cursor-pointer"
        onClick={() => navigate('/passwordChange')}>
        <div>비밀번호 변경</div>
        <div>
          <img src={arrow} alt="화살표" />
        </div>
      </TopBox>
    </LayoutBgGray>
  );
};

export default Mypage;
