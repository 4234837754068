import { format, formatDistanceToNow } from 'date-fns';

export function fTime(date) {
  return date ? format(new Date(date), 'HH:mm') : '';
}

export function fDay(date) {
  return date ? format(new Date(date), 'MM-dd') : '';
}

export function fDate(date) {
  return date ? format(new Date(date), 'yyyy-MM-dd') : '';
}

export function fDateTime(date) {
  return date ? format(new Date(date), 'yyyy-MM-dd HH:mm') : '';
}

export function fDateTimeSuffix(date) {
  return date ? format(new Date(date), 'yyyy/MM/dd hh:mm p') : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true
      })
    : '';
}
