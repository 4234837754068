import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
//초기화를 위해사용
import { PURGE } from 'redux-persist';

// utils

import axios from 'axios';
import { cleanObject, headers, jsonHeader, urls } from '../../libs/reqConf';
import { getOpen, getOpenAlert } from './modal';
import { checkTokenAndRefresh } from '../../utils/function';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isLogin: false,
  error: false,
  myProfile: {},
  user: {}
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // 로그인 성공
    getLoginSuccess(state, action) {
      state.isLoading = false;
      state.isLogin = true;
      state.user = action.payload;
    },

    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // 로그인 성공
    getProfileUpdateSuccess(state, action) {
      state.isLoading = false;
      state.user = action.payload;
    },

    // 로그아웃 성공
    getLogoutSuccess(state) {
      state.isLoading = false;
      state.isLogin = false;
      state.user = {};
      localStorage.clear();
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(
        state.userList,
        (user) => user.id !== action.payload
      );
      state.userList = deleteUser;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser } = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/account/profile`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getProfileSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
//로그인
export function getLogin(params) {
  return async (dispatch) => {
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      window.localStorage.setItem('remember', params?.remember);
      const url = `${urls.user}/account/login`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);

      window.localStorage.setItem(
        'accessToken',
        response.data.data.accessToken
      );
      dispatch(slice.actions.getLoginSuccess(params.studentID));
    } catch (error) {
      console.log('error', error?.response);
      dispatch(slice.actions.hasError(error?.response?.data));
      dispatch(getOpen(error?.response?.data));
    }
  };
}
// ----------------------------------------------------------------------
//로그인
export function getLogout() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.getLogoutSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError('로그아웃 실패'));
    }
  };
}

// ----------------------------------------------------------------------
//토큰 재발행
export function getRefreshToken() {
  return async (dispatch) => {
    try {
      let accessToken = window.localStorage.getItem('accessToken');
      const url = `${urls.user}/account/token/refresh`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      console.log('res', response.data.data);
      window.localStorage.setItem(
        'accessToken',
        response.data.data.accessToken
      );
      accessToken = response?.data?.data?.accessToken;
      return accessToken;
    } catch (error) {
      console.log('error', error?.response);
      dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}

// ----------------------------------------------------------------------
//프로필 수정
export function updateProfile(parames) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/account/profile`;
      const body = cleanObject(parames);
      const config = { headers: headers(accessToken) };
      await axios.patch(url, body, config);

      return 'success';
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error.response?.data?.response?.message));
    }
  };
}

// ----------------------------------------------------------------------
//비밀번호 변경
export function updatePassword(parames) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/account/password`;
      const body = cleanObject(parames);
      const config = { headers: headers(accessToken) };
      await axios.patch(url, body, config);

      return 'success';
    } catch (error) {
      console.log('error', error.response?.data?.response?.message);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpenAlert(error.response?.data?.response?.message));
    }
  };
}

// ----------------------------------------------------------------------
//익명토큰 발행
export function getAnonymousToken() {
  return async (dispatch) => {
    try {
      const url = `${urls.user}/account/anonymous/token`;
      const config = { headers: jsonHeader() };
      const response = await axios.get(url, config);
      const anonymousToken = response.data.data.anonymousToken;

      window.localStorage.setItem('anonymousToken', anonymousToken);

      return anonymousToken;
    } catch (error) {
      console.log('error', error?.response);
      dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}

// ----------------------------------------------------------------------
//익명토큰 재발행
export function getRefreshAnonymousToken() {
  return async (dispatch) => {
    try {
      let anonymousToken = window.localStorage.getItem('anonymousToken');
      const url = `${urls.user}/account/anonymous/refresh`;
      const config = { headers: headers(anonymousToken) };
      const response = await axios.get(url, config);
      anonymousToken = response?.data?.data?.anonymousToken;

      window.localStorage.setItem('anonymousToken', anonymousToken);

      return anonymousToken;
    } catch (error) {
      console.log('error', error?.response);
      dispatch(slice.actions.hasError(error?.response?.data));
    }
  };
}
