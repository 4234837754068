import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Pagination } from '@mui/material';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import {
  BlueBox,
  Button,
  Card,
  LayoutBgGray,
  Tag,
  WhiteBox,
  WriteButton
} from '../component/Styled';
import BackHeader from '../component/BackHeader';

import { fDateTime } from '../utils/formatTime';
import { getTimeDifference } from '../utils/function';

import usePaging from '../hooks/usePaging';
import plus from '../asset/img/ic/plus.webp';

const SchedulerDetail = () => {
  const [buttondisable, setButtonDisable] = useState(true);
  const { state } = useLocation();
  const { events } = useSelector((state) => state?.schedule);
  const { page, itemsPerPage, handleChangePage, startIndex, endIndex } =
    usePaging(Number(1));
  const { data, mentor } = state;
  // 활동일지
  const activities = events[data?._id]?.activities;
  // 이중 배열 방지
  const journalArr = activities?.flatMap((el) => el.journals);
  const filter = activities?.find((el) => el.date === data?.date);
  // filter?.journals 배열에서 getTimeDifference 함수의 리턴 값들을 모두 더하기
  let time = 0; // 초기값 설정

  if (filter?.journals) {
    time = journalArr.reduce((accumulator, journal) => {
      const difference = getTimeDifference(journal?.startAt, journal?.endAt);
      return accumulator + Number(difference);
    }, 0);
  }
  const displayedJournal = journalArr?.slice(startIndex, endIndex);

  const navigate = useNavigate();
  useEffect(() => {
    const now = new Date();
    const start = new Date(data?.startDate);

    // 현재 시간이 종료 시간 이후이면 버튼 활성화
    if (now >= start) {
      setButtonDisable(false);
    }
    // 페이지 초기화용
    localStorage.setItem('pageNumber', 1);
  }, []);

  return (
    <LayoutBgGray style={{ height: '100%' }}>
      <BackHeader ment={'일정 상세정보'} main={true} />
      <Card className="w-full px-5 py-4">
        <div className="flex pt-2 flex-col items-start self-stretch  border-b border-solid border-[#e4e4e4]">
          <div className="text-text1 text-15 font-semibold">
            {data?.category?.title}
          </div>
          <div className="text-text1 text-20 font-bold">{data?.title}</div>
          <div className="text-14 text-text3 mt-2">{mentor?.nickname}</div>
          <div className="text-14 text-text3 mt-2">
            시작시간 - {fDateTime(data?.startDate)}
          </div>
          <div className="text-14 text-text3 mb-2">
            종료시간 - {fDateTime(data?.endDate)}
          </div>
        </div>

        <Button
          disabled={buttondisable}
          color={buttondisable ? '#D9D9D9' : '#0054F7'}
          className="text-main2 mt-6"
          onClick={() =>
            navigate('/reviewWrite', { state: { ...events[data?._id], data } })
          }>
          {events[data?._id]?.review ? '작성후기 보기' : '소감/후기작성'}
        </Button>
      </Card>
      {/* 카테고리가 직장체험 일때만 활동일지 보이게 설정 */}
      {data?.category?.title === '직장체험' && (
        <Card className="w-full px-5 py-4 mt-4">
          <>
            <div className="flex w-full justify-between items-center">
              <div>활동일지</div>
              <WriteButton
                onClick={() =>
                  navigate('/journalWrite', {
                    state
                  })
                }>
                <div className="mr-2">
                  <img src={plus} alt="플러스" />
                </div>
                <div className="pt-0.5 text-text4">작성하기</div>
              </WriteButton>
            </div>
            <div className="mt-4 text-14 text-text10">
              작성시간 {time}h / {data?.totalCompletionTime}
            </div>
          </>

          {isEmpty(journalArr) ? (
            <BlueBox style={{ padding: '12px', marginTop: '8px' }}>
              <div className="text-text3">작성된 활동일지가 없습니다.</div>
            </BlueBox>
          ) : (
            displayedJournal.map((journal, _idx) => {
              return (
                <>
                  <BlueBox
                    key={_idx}
                    className="mt-4"
                    style={{ padding: '12px' }}
                    onClick={() =>
                      navigate(`/journalUpdate/${journal?._id}`, {
                        state: { journal, data }
                      })
                    }>
                    <div className="text-14 text-text3 mt-2 mb-2">
                      {fDateTime(journal?.createdAt)}
                    </div>
                    <p className="text-14 text-text3">{journal?.content}</p>
                    {!isEmpty(journal?.comment) && (
                      <WhiteBox style={{ padding: '8px 10px', height: '100%' }}>
                        <div className="flex">
                          <Tag className="text-13 mb-3">선생님 코멘트</Tag>
                        </div>
                        <p className="text-14 text-text3 text-ellipsis overflow-hidden">
                          {journal?.comment?.content}
                        </p>
                      </WhiteBox>
                    )}
                  </BlueBox>
                </>
              );
            })
          )}

          {displayedJournal?.length !== 0 && (
            <div className="flex w-full justify-center">
              <Pagination
                count={Math.ceil(journalArr?.length / itemsPerPage)}
                page={Number(page)}
                onChange={handleChangePage}
                color="primary"
              />
            </div>
          )}

          <div className="mb-5"></div>
        </Card>
      )}
    </LayoutBgGray>
  );
};

export default SchedulerDetail;
