import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '@mui/material';
import { getNotice } from '../store/slices/ilharu';
import usePaging from '../hooks/usePaging';

//컴포넌트
import { LayoutBgGray, Tag, WhiteBox } from '../component/Styled';
import BackHeader from '../component/BackHeader';
import { getSurvey } from '../store/slices/survey';
import { fDate, fTime } from '../utils/formatTime';

const Survey = () => {
  const navigate = useNavigate();
  const { survey } = useSelector((state) => state.survey);
  console.log('survey', survey);
  const dispatch = useDispatch();
  const { page, itemsPerPage, handleChangePage, startIndex, endIndex } =
    usePaging(1);
  useEffect(() => {
    dispatch(getSurvey());
  }, []);

  const displayedSurveys =
    survey?.length > 0 ? survey.slice(startIndex, endIndex) : [];

  // 또는
  // const displayedSurveys = survey?.length > 0 ? survey.slice(startIndex, endIndex) : null;

  const handleNavigate = (survey) => {
    navigate(`/surveyDetail/public/${survey?._id}`, {
      state: { ...survey, public: true }
    });
  };

  return (
    <LayoutBgGray>
      <BackHeader ment={'설문'} />
      {survey?.length === 0 && (
        <div className="text-center">설문이 없습니다.</div>
      )}
      {displayedSurveys.map((survey, idx) => (
        <WhiteBox
          key={idx}
          data={survey}
          onClick={() => handleNavigate(survey)}>
          <div className="flex justify-between mt-2 px-1">
            <div>
              <div className="text-12 text-text3">[설문]</div>
              <div className="font-medium text-text1">{survey?.title}</div>
            </div>
            <div>
              <div className="text-14 text-text8 font-medium">
                {fDate(survey?.createdAt)}
              </div>
              <div className="text-14 text-text8 text-right font-medium">
                {fTime(survey?.createdAt)}
              </div>
            </div>
          </div>
        </WhiteBox>
      ))}

      {displayedSurveys?.length !== 0 && (
        <Pagination
          count={Math.ceil(survey.length / itemsPerPage)}
          page={Number(page)}
          onChange={handleChangePage}
          color="primary"
        />
      )}
    </LayoutBgGray>
  );
};

export default Survey;
