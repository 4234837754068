/* eslint-disable array-callback-return */
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import {
  BlueBox,
  LayoutBgGray,
  TopBox,
  AbsoluteButton
} from '../component/Styled';
import BackHeader from '../component/BackHeader';
import { styled } from 'styled-components';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAnonymousSurveyAnswer,
  getAnonymousSurveyDetail
} from '../store/slices/survey';
import BasicModal from '../component/Modal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  getAnonymousToken,
  getRefreshAnonymousToken
} from '../store/slices/user';
import { isValidToken } from '../utils/jwt';

const TitleBox = styled.input`
  display: flex;
  width: 304px;
  padding: 12px 6px;
  align-items: center;
  border-bottom: 1px solid #c6c6c;
  background: #fff;
  margin-bottom: 12px;
`;

const SurveyDetailPublic = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const params = useParams();
  const { selectedSurvey } = useSelector((state) => state?.survey);
  const [answer, setAnswer] = useState();
  const [ownProperty, setOwnProperty] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [anonymousToken, setAnonymousToken] = useState(
    window.localStorage.getItem('anonymousToken')
  );

  // 익명토큰 관리
  useEffect(() => {
    (async () => {
      let token = null;
      // 토큰이 없을경우 발행
      if (isEmpty(anonymousToken)) {
        token = await dispatch(getAnonymousToken());
        setAnonymousToken(token);
      } else {
        // 토큰이 있을경우 유효여부 검증. 유효하지 않은 토큰은 재발행
        if (!isValidToken(anonymousToken)) {
          token = await dispatch(getRefreshAnonymousToken());
          setAnonymousToken(token);
        }
      }
    })();
  }, [anonymousToken]);

  useEffect(() => {
    if (!isEmpty(anonymousToken) && isValidToken(anonymousToken)) {
      const fetchData = async () => {
        try {
          const res = await dispatch(getAnonymousSurveyDetail(params?.id));
          if (isEmpty(!res)) setIsReady(true);

          // 여기에서 다른 비동기 작업을 수행할 수 있습니다.
          const answerSurvey = res.map((question, _idx) => {
            if (question?.type === 'text') {
              return { ...question, textAnswer: '' };
            } else if (question?.type === 'check') {
              return { ...question, checkSelections: [...question?.options] };
            } else {
              return { ...question, radioSelection: [...question?.options] };
            }
          });

          setAnswer(answerSurvey);
        } catch (error) {
          // 에러 처리
          console.error('에러 발생:', error);
        }
      };

      fetchData();
    }
  }, [anonymousToken]);

  const handleAnswer = (type, event, idx, num) => {
    setOwnProperty(false);
    const { value } = event.target;

    let copyArr;
    copyArr = [...answer];
    console.log('copyArr', copyArr[0].checkSelections);

    switch (type) {
      case 'text':
        copyArr[idx] = { ...copyArr[idx], textAnswer: value };
        break;
      case 'check':
        const checkArr = copyArr[idx].checkSelections.map((item, i) => {
          if (i === num) {
            // 해당 아이템의 복사본을 만들어 checked 속성 변경
            return { ...item, checked: event.target.checked };
          }
          return item;
        });

        // 기존 객체의 복사본을 만들어서 checkSelections 속성 업데이트
        copyArr[idx] = { ...copyArr[idx], checkSelections: checkArr };
        break;
      default:
        const callback = (el) => ({ ...el, selected: false });
        const resetPrevSelection = copyArr[idx].radioSelection.map(callback);
        const radioArr = resetPrevSelection.map((item, i) => {
          if (i === num) {
            // 해당 아이템의 복사본을 만들어 checked 속성 변경
            return { ...item, selected: event.target.checked };
          }
          return item;
        });

        // 기존 객체의 복사본을 만들어서 checkSelections 속성 업데이트
        copyArr[idx] = { ...copyArr[idx], radioSelection: radioArr };
    }

    setAnswer(copyArr);
  };

  const handleSubmit = async (id) => {
    // answer 객체에서 속성 이름이 있는지 확인

    const checkConditions = answer.map((element) => {
      if (element?.type === 'text') {
        return !isEmpty(element?.textAnswer);
      } else if (element?.type === 'check') {
        return element?.checkSelections.some((item) => item?.checked);
      } else {
        return element?.radioSelection.some((item) => item?.selected);
      }
    });
    console.log('checkConditions', checkConditions);

    // checkConditions 배열에 모두 false인지 확인
    const allFalse = checkConditions.every((condition) => condition === true);

    if (allFalse) {
      try {
        const res = await dispatch(getAnonymousSurveyAnswer(id, answer));

        if (res === 'success') {
          toast('설문 완료');
          setTimeout(() => navigate('/'), 1000);
        }
      } catch (error) {
        // 에러 처리
        console.log('에러 발생:', error);
      }
    } else {
      setOwnProperty(true);
    }
  };

  const questionSwitch = (el, idx) => {
    const currentAnswer = answer.find((each) => each?.index === el?.index);

    switch (el?.type) {
      case 'text':
        return (
          <>
            <div className="font-semibold mb-2">{el?.question}</div>
            <TitleBox
              type="text"
              placeholder="답변을 남겨주세요."
              onChange={(event) => handleAnswer('text', event, idx)}
            />
          </>
        );
      case 'check':
        return (
          <>
            <div className="font-semibold">{el?.question}</div>
            <div className="text-text1">
              나에게 맞는 것을 모두 선택해 주세요.
            </div>
            <div className="grid grid-cols-2  gap-4 text-text3 tracking-[-0.5px] mb-3">
              {el?.options.map((el, num) => {
                return (
                  <div key={num} className="flex">
                    <label className="flex items-center">
                      <input
                        className="custom-checkbox w-20 h-20 mr-2"
                        type="checkbox"
                        onChange={(event) =>
                          handleAnswer('check', event, idx, num)
                        }
                      />
                      <span className="align-top">{el?.label}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          </>
        );
      default:
        return (
          <div>
            <div className="font-semibold ">{el?.question}</div>
            <div className="text-text1">
              나에게 맞는 것을 하나만 선택해 주세요.
            </div>
            <div className="flex flex-col gap-4 text-text3 tracking-[-0.5px]">
              {el?.options.map((el, num) => {
                const prevCallback = (value) => value?._id === el?._id;
                const prev = currentAnswer?.radioSelection.find(prevCallback);

                return (
                  <div key={el?._id} className="flex">
                    <label className="flex items-center">
                      <input
                        key={el?._id}
                        className="custom-radio w-20 h-20 mr-2"
                        type="radio"
                        name={el?._id}
                        checked={prev?.selected}
                        onChange={(event) =>
                          handleAnswer('radio', event, idx, num)
                        }
                      />
                      <span className="align-top">{el?.label}</span>
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        );
    }
  };

  return (
    isReady && (
      <LayoutBgGray>
        <BackHeader ment={'설문참여'} main={true} />
        <BasicModal />
        <ToastContainer
          position="top-right" // 알람 위치 지정
          autoClose={500} // 자동 off 시
        />
        <TopBox className="flex flex-col p-[16px] gap-2">
          <div className="text-20 text-text1 tracking-[-0.036px] font-bold">
            {selectedSurvey?.title}
          </div>
          <div className="text-text2 font-semibold"></div>
          <div className="text-14 text-text3 font-normal">
            {selectedSurvey?.details}
          </div>
        </TopBox>
        <BlueBox className="flex flex-col">
          {selectedSurvey?.questions?.map((el, idx) => {
            return questionSwitch(el, idx);
          })}

          {ownProperty && (
            <div className="text-error text-15 text-center">
              답변하지 않은 문항을 답변해주세요
            </div>
          )}
        </BlueBox>
        <AbsoluteButton
          disabled={state?.isComplete}
          className="text-main2"
          color={state?.isComplete ? '#ebebeb' : '#0F62FE'}
          onClick={() => handleSubmit(selectedSurvey?._id)}>
          제출하기
        </AbsoluteButton>
      </LayoutBgGray>
    )
  );
};

export default SurveyDetailPublic;
