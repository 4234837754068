import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { headers, jsonHeader, urls } from '../../libs/reqConf';
import { getOpen } from './modal';
//초기화를 위해사용
import { PURGE } from 'redux-persist';
import {
  checkTokenAndRefresh,
  compareFavorites,
  isStartAtCheck
} from '../../utils/function';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  activitys: [],
  mentors: [],
  centers: {},
  categories: [],
  notices: [],
  favorite: []
};

const slice = createSlice({
  name: 'ilharu',
  initialState,
  reducers: {
    handleDialog(state, action) {
      state.dialog = action.payload;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET scheduleHistorys
    getScheduleHistorysSuccess(state, action) {
      state.scheduleHistorys = action.payload;
    },
    // GET 멘토
    getMentorsSuccess(state, action) {
      action.payload.sort((a, b) => (a.isMyMentor === true ? -1 : 1));
      action.payload.sort((a, b) => (a.isMyTeacher === true ? -1 : 1));

      state.isLoading = false;
      state.mentors = action.payload;
    },

    // GET 카테고리
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // GET 기관
    getCenterSuccess(state, action) {
      state.isLoading = false;
      state.centers = action.payload;
    },

    // GET 멘토
    getNoticesSuccess(state, action) {
      action.payload.sort(compareFavorites);
      const filteredData = action.payload.filter((item) => {
        if (item.type === 'survey') {
          // type이 'survey'인 경우에만 검사를 수행
          return isStartAtCheck(item.startAt);
        }
        // 다른 타입인 경우는 모두 포함
        return true;
      });
      state.isLoading = false;
      state.notices = filteredData;
    },

    // GET 멘토
    getActivitySuccess(state, action) {
      state.isLoading = false;
      state.activitys = action.payload;
    },

    // CREATE Review
    noticeReadSuccess(state, action) {
      console.log('action.payload', action.payload);
      state.isLoading = false;
    },

    // UPDATE Review
    updateReviewSuccess(state, action) {
      console.log('updateReviewSuccess action', action.payload);
      state.isLoading = false;
    },

    // CREATE Journal 활동일지 작성
    createJournalSuccess(state, action) {
      console.log('createJournalSuccess action', action.payload);
      state.isLoading = false;
    },

    // UPDATE Journal
    addFavoriteSuccess(state, action) {
      state.isLoading = false;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    },
    changeIsVisit(state) {
      localStorage.setItem('hasVisited', true);
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// 멘토조회
export function getMentors() {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/mentors`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getMentorsSuccess(response.data.data));
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
} // ----------------------------------------------------------------------
// 멘토 카테고리조회
export function getMentorCategory() {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/mentors/categories`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getCategoriesSuccess(response.data.data));
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
// 멘토기관
export function getCenter() {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/network-organizations`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getCenterSuccess(response.data.data));
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
// 멘토기관
export function getNotice() {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/notices`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getNoticesSuccess(response.data.data));
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
// 활동내역조회
export function getActivity() {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/activities`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      dispatch(slice.actions.getActivitySuccess(response.data.data));
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
// 공지사힝 읽음
export function noticeRead(noticeId) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);

      const url = `${urls.user}/notices/read/${noticeId}`;
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, {}, config);

      dispatch(slice.actions.noticeReadSuccess(response.data?.success));
    } catch (error) {
      console.log('error', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}
// ----------------------------------------------------------------------
// 공지사항 추가
export function addFavorite(noticeId) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/notices/favorite/${noticeId}`;
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, {}, config);
      dispatch(slice.actions.addFavoriteSuccess(response.data.data));
      dispatch(getNotice());
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
// 공지사항 삭제
export function deleteFavorite(noticeId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);

      const url = `${urls.user}/notices/favorite/${noticeId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(getNotice());
      return 'success';
    } catch (error) {
      console.log('error', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// 방문자수
export function getVisit() {
  return async (dispatch) => {
    try {
      const url = `${urls.kpi}/visit`;
      const res = await axios.post(url);
      console.log('res', res);
      dispatch(slice.actions.changeIsVisit());
    } catch (error) {
      console.log('error', error);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
// 클릭 체크
export function getExternal(params) {
  return async (dispatch) => {
    try {
      const url = `${urls.kpi}/external`;
      const body = {
        type: '2',
        kpiExternalId: params
      };
      const res = await axios.post(url, body);

      console.log('res', res);
    } catch (error) {
      console.log('error', error);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}
