import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isOpen: false,
  msg: ''
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    openModal: (state, actions) => {
      state.isOpen = true;
      state.msg = actions.payload;
    },
    closeModal: (state) => {
      state.isOpen = false;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModSelfPlan, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getOpen(msg) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.openModal(msg?.response?.message));
    } catch (error) {
      console.log('error', error);
    }
  };
}

// ----------------------------------------------------------------------

export function getOpenAlert(msg) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.openModal(msg));
    } catch (error) {
      console.log('error', error);
    }
  };
}

// ----------------------------------------------------------------------

export function getClose() {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.closeModal());
    } catch (error) {
      console.log('error', error);
    }
  };
}
