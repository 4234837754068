import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';
import './font.css';
import reportWebVitals from './reportWebVitals';
// redux
import store, { persistor } from './store';
import { PersistGate } from 'redux-persist/integration/react';
import { Provider } from 'react-redux';
const { REACT_APP_NODE_ENV } = process.env;

const root = ReactDOM.createRoot(document.getElementById('root'));

if (REACT_APP_NODE_ENV === 'production') {
  console.log = console.warn = console.error = () => {};
}

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
