import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { cleanObject, headers, urls } from '../../libs/reqConf';
import { getOpen } from './modal';
import { checkTokenAndRefresh } from '../../utils/function';
import { PURGE } from 'redux-persist';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  events: {},
  review: {},
  journal: {},
  dates: [],
  dayEvents: [],
  targetDate: '',
  scheduleHistorys: []
};

const slice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    handleDialog(state, action) {
      state.dialog = action.payload;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      console.log('hasError', action.payload);
      state.isLoading = false;
      state.error = action.payload;
    },
    // GET scheduleHistorys
    getScheduleHistorysSuccess(state, action) {
      state.isLoading = false;
      state.scheduleHistorys = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.isLoading = false;
      const valueArr = Object.values(action.payload);
      state.events = action.payload;
      //상태 delete 제거

      const filterArr = valueArr.filter(
        (value) => value.activities[0].status !== 'delete'
      );

      const dateArr = filterArr.map((el) => el.dates);
      // Set을 사용하여 중복을 제거하고 하나의 배열로 합침
      state.dates = [...new Set([].concat(...dateArr))];
    },

    // 선택날짜 일정 가져오기
    getDayEventsSuccess(state, action) {
      state.isLoading = false;
      state.targetDate = action.payload;
      const { events } = state;
      const resultArray = [];
      // 데이터 객체 순회
      for (const key in events) {
        // 키값을 꺼냄
        if (events.hasOwnProperty(key)) {
          const innerData = events[key];

          // innerData.activities 배열 순회
          for (const activity of innerData.activities) {
            if (activity.date === state?.targetDate) {
              //상태 delete 제거
              if (activity.status !== 'delete') {
                resultArray.push(activity); // 결과 배열에 추가
              }
            }
          }
        }
      }
      state.dayEvents = resultArray;
    },

    // CREATE Review
    createReviewSuccess(state, action) {
      state.isLoading = false;
      state.review = action.payload;
    },

    // UPDATE Review
    updateReviewSuccess(state, action) {
      state.isLoading = false;
    },

    // CREATE Journal 활동일지 작성
    createJournalSuccess(state, action) {
      state.isLoading = false;
    },

    // UPDATE Journal
    updateJouralSuccess(state, action) {
      state.isLoading = false;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const eventId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = eventId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { startDate, endDate } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { startDate, endDate };
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
export function getScheduleHistorys() {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/schedules/list`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      console.log('response', response.data.data);
      dispatch(slice.actions.getScheduleHistorysSuccess(response.data.data));
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getEvents() {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/schedules`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getEventsSuccess(response.data.data));
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getDayEvents(targetDay) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    await checkTokenAndRefresh(dispatch);
    try {
      dispatch(slice.actions.getDayEventsSuccess(targetDay));
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
// 후기 작성
export function createReview(scheduleId, params) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/schedules/reviews/${scheduleId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.createReviewSuccess(response.data.data));
      dispatch(getEvents());
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}
// ----------------------------------------------------------------------
// 후기 수정
export function updateReview(reviewId, params) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/schedules/reviews/${reviewId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      dispatch(slice.actions.updateJouralSuccess(response.data.data));
      dispatch(getEvents());
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
//후기 삭제

export function deleteWrite(reviewId) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/schedules/reviews/${reviewId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(getEvents());
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// 활동일지 ----------------------------------------------------------------------

export function createJournal(scheduleId, params) {
  console.log('params', params);
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/schedules/journals/${scheduleId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);
      console.log('create journal response', response.data.data);
      dispatch(slice.actions.createJournalSuccess(response.data.data));
      dispatch(getEvents());

      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}
// ----------------------------------------------------------------------

export function updateJournal(journalId, params) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/schedules/journals/${journalId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      dispatch(slice.actions.updateReviewSuccess(response.data.data));
      dispatch(getEvents());
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
//활동일지 삭제

export function deleteJournal(journalId) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/schedules/journals/${journalId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(getEvents());
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}
