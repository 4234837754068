import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useLocation, useNavigate } from 'react-router-dom';
import { useCRUD } from '../hooks/useCRUD';
import {
  createSelfPlan,
  deleteSelfPlan,
  updateSelfPlan
} from '../store/slices/selfPlan';

// 컴포넌트
import {
  BlueBox,
  Button,
  Card,
  LayoutBgGray,
  Paper
} from '../component/Styled';
import BackHeader from '../component/BackHeader';
import BasicModal from '../component/Modal';

import { toast, ToastContainer } from 'react-toastify';
import { isEmpty } from 'lodash';
import { service } from '../utils/listData';

import 'react-datepicker/dist/react-datepicker.css';
import './date.css';
import 'react-toastify/dist/ReactToastify.css';

const SelfPlanWrite = () => {
  const { state } = useLocation();
  const navigate = useNavigate();
  const { handleCreate, handleUpdate, handleDelete } = useCRUD();

  const [checkList, setCheckList] = useState({
    careerJobExperience: false,
    mentoring: false,
    workplaceExperience: false,
    independenceSkillTraining: false,
    certificationAcquisition: false,
    jobTraining: false
  });

  const hadleCheckList = (event) => {
    const newObj = { ...checkList };
    newObj[event.target.value] = !newObj[event.target.value];

    setCheckList(newObj);

    setFieldValue(
      'preferredService',
      // true 키값만
      Object.keys(newObj).filter((key) => newObj[key] === true)
    );
  };

  const deleteData = () => {
    const res = handleDelete(deleteSelfPlan, state?._id);
    if (res) {
      toast('삭제 성공');
      setTimeout(() => navigate(-1), 1000);
    }
  };

  const writeSchema = Yup.object().shape({
    goal: Yup.string().required('자립목표를 해주세요.'),
    detailedPlan: Yup.string().required('세부계획를 입력해주세요.'),
    preferredService: Yup.array(Yup.string()).required(
      '희망서비스를 선택해주세요.'
    )
  });
  const formik = useFormik({
    validationSchema: writeSchema,
    writeSchema,
    initialValues: {
      goal: '' || state?.goal,
      detailedPlan: '' || state?.detailedPlan,
      preferredService: '' || state?.preferredService,
      writeAt: new Date() || state?.writeAt
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log('values', values);
      let res;
      if (state) {
        res = await handleUpdate(updateSelfPlan, state?._id, values);

        if (res) {
          toast('수정 성공');
          setTimeout(() => navigate(-1), 1000);
        }
      } else {
        res = await handleCreate(createSelfPlan, values);
        if (res) {
          toast('작성 성공');
          setTimeout(() => navigate(-1), 1000);
        }
      }

      try {
      } catch (error) {
        const { response } = error;
        const afterSubmit = response.data.response.message;
        setErrors({ afterSubmit });
      }
    }
  });
  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue
  } = formik;

  useEffect(() => {
    if (state) {
      state?.preferredService.map((el) => {
        if (Object.keys(checkList).includes(el)) {
          setCheckList((checkList) => {
            const newObj = { ...checkList };
            newObj[el] = true;
            return newObj;
          });
        }
      });
    }
  }, []);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <LayoutBgGray>
          <BasicModal />

          <ToastContainer
            position="top-right" // 알람 위치 지정
            autoClose={500} // 자동 off 시
          />
          <BackHeader ment={'자립계획서 작성'} main={true} />
          {!isEmpty(state?.comment) && (
            <BlueBox
              className="mx-auto flex flex-col"
              style={{ cursor: 'default' }}>
              <div className="text-13 text-text2">
                담당선생님 {state?.teacher?.nickname}
              </div>
              <p className="text-14 text-text3 mt-3 whitespace-pre-wrap">
                {state?.comment?.content}
              </p>
            </BlueBox>
          )}
          <Card className="px-4 py-5">
            <div className="text-text1 text-15 font-medium mb-1">자립목표</div>
            <Paper
              placeholder={`자립 목표 작성`}
              {...getFieldProps('goal')}
              error={Boolean(touched.goal && errors.goal)}
            />
            <div className="text-error text-13">
              {touched.goal && errors.goal}
            </div>
            <div className="text-text1 text-15 font-medium mt-4 mb-1">
              세부 계획
            </div>
            <Paper
              placeholder={`세부 계획 작성`}
              {...getFieldProps('detailedPlan')}
              error={Boolean(touched.detailedPlan && errors.detailedPlan)}
            />
            <div className="text-error text-13">
              {touched.detailedPlan && errors.detailedPlan}
            </div>
            <div className="width-266">
              <div className="text-text1 text-15 font-medium mt-8 mb-3">
                희망서비스
              </div>
              <div className="grid grid-cols-2 gap-x-8 gap-y-6">
                {service.map((el, idx) => {
                  return (
                    <label className="flex items-center" key={idx}>
                      <input
                        className="custom-checkbox2 w-20 h-20 mr-2"
                        type="checkbox"
                        value={el?.value}
                        onClick={hadleCheckList}
                        checked={checkList[el?.value]}
                      />
                      <span className="align-top">{el.text}</span>
                    </label>
                  );
                })}
              </div>
            </div>
            <div className="text-error text-13">
              {touched.preferredService && errors.preferredService}
            </div>
          </Card>
          <div className="text-center">
            <Button
              type="submit"
              style={{ height: '56px' }}
              color="#0054F7"
              className="text-main2 mt-6 mb-4">
              저장
            </Button>
            {!isEmpty(state) && (
              <div
                className="underline text-14 text-text11 text-center mb-10 cursor-pointer tracking-[-0.5px]"
                onClick={deleteData}>
                자립계획서 삭제
              </div>
            )}
          </div>
        </LayoutBgGray>
      </Form>
    </FormikProvider>
  );
};

export default SelfPlanWrite;
