import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  addFavorite,
  deleteFavorite,
  noticeRead
} from '../store/slices/ilharu';
import { styled } from 'styled-components';
import { AbsoluteButton, Layout } from '../component/Styled';
import BackHeader from '../component/BackHeader';

const BlueBox = styled.div`
  padding: 8px 140px 8px 12px;
  align-items: center;
  border-radius: 8px;
  background: #e3eeff;
  margin-top: 16px;
`;

const NoticeDetail = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const teacher = state?.teacher;
  console.log('state: ', state);
  const [checkFavorite, setCheckFavorite] = useState(state?.isFavorite);

  useEffect(() => {
    dispatch(noticeRead(state?._id));
  }, []);

  const handleFavorite = (id) => {
    checkFavorite ? dispatch(deleteFavorite(id)) : dispatch(addFavorite(id));
    setCheckFavorite(!checkFavorite);
  };

  return (
    <Layout>
      <BackHeader ment={'공지사항 상세보기'} main={true} />
      <div className="flex pt-2 flex-col items-start self-stretch gap-2 border-b border-solid border-[#e4e4e4] mb-4">
        <div className="text-text1 font-20 font-bold mb-4">{state?.title}</div>
        {/* <div className="mb-4">학교 밖 청소년 지원센터</div> */}
      </div>
      <text
        className="text-14 text-text3 whitespace-pre-wrap mt-6"
        dangerouslySetInnerHTML={{ __html: state?.content }}></text>
      <BlueBox style={{ padding: '10px' }}>
        <div className="text-14 text-text3 ">문의 및 담당자</div>
        <div className="text-14 text-text1 font-semibold">
          담당자:{' '}
          {isEmpty(teacher?.teacherAbout) ? '미등록' : teacher?.teacherAbout}{' '}
          {isEmpty(teacher?.phoneNumber) ? '(미등록)' : teacher?.phoneNumber}
        </div>
      </BlueBox>
      <div className="mt-4">
        {state?.noticeImage ? (
          <img width={'100%'} src={state?.noticeImage?.url} alt="샘플" />
        ) : (
          <div>이미지가 없습니다.</div>
        )}
      </div>

      <AbsoluteButton
        className="text-main2"
        color={checkFavorite ? '#c6c6c6' : '#0F62FE'}
        onClick={() => handleFavorite(state?._id)}>
        {checkFavorite ? '즐겨찾기 해제' : '즐겨찾기 등록'}
      </AbsoluteButton>
    </Layout>
  );
};

export default NoticeDetail;
