import React from 'react';
import BackHeader from '../component/BackHeader';
import kakaoButton from '../asset/img/kakaoButton.svg';
import instaButton from '../asset/img/instaButton.svg';
import styled from 'styled-components';
import { imageDream, imageIlharu, imageCenter } from '../utils/listData';
import { useDispatch } from 'react-redux';
import { getExternal } from '../store/slices/ilharu';

export const LayoutBgGray = styled.div`
  max-width: 500px;
  width: 100%;
  height: 100%;
  max-height 1500px;
  margin: 0 auto;
  position: relative;
  background: #f8f8f8;
  padding: 0 18px 20px; 
`;

const MentDiv = styled.div`
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 25px;
`;

const ImgBox = styled.div`
  display: flex;
  overflow: hidden;
  overflow-x: visible;
`;
const Introduce = () => {
  const dispatch = useDispatch();
  const kakao = '639c01579c29140012f019cc';
  const insta = '656961c69b9ba003fa660716';
  return (
    <LayoutBgGray>
      <BackHeader ment="일하루 소개" />

      <MentDiv>꿈드림</MentDiv>
      <ImgBox>
        {imageDream.map((img, idx) => (
          <img key={idx} width={'100%'} src={img?.src} alt="꿈드림" />
        ))}
      </ImgBox>
      <MentDiv>일하루</MentDiv>
      <ImgBox>
        {imageIlharu.map((img, idx) => (
          <img key={idx} width={'100%'} src={img?.src} alt="일하루" />
        ))}
      </ImgBox>

      <MentDiv>센터 정보</MentDiv>
      <ImgBox>
        {imageCenter.map((img, idx) => (
          <img key={idx} width={'100%'} src={img?.src} alt="센터" />
        ))}
      </ImgBox>

      <MentDiv>공지사항</MentDiv>
      <div className="mt-5" onClick={() => dispatch(getExternal(kakao))}>
        <a href="https://pf.kakao.com/_LJGSxb" target="_blank" rel="noreferrer">
          <img
            className="block mx-auto"
            width={'95%'}
            src={kakaoButton}
            alt="이미지"
          />
        </a>
      </div>
      <MentDiv>활동소식</MentDiv>
      <div className="mt-5" onClick={() => dispatch(getExternal(insta))}>
        <a
          href="https://www.instagram.com/sungnamkdream/"
          target="_blank"
          rel="noreferrer">
          <img
            className="block mx-auto"
            width={'95%'}
            src={instaButton}
            alt="이미지"
          />
        </a>
      </div>
    </LayoutBgGray>
  );
};

export default Introduce;
