import React, { useEffect } from 'react';
import { TopBox } from './Styled';
import arrow from '../asset/img/ic/arrow.svg';
import grayStar from '../asset/img/ic/grayStar.svg';
import yellowStar from '../asset/img/ic/yellowStar.svg';
import blueStar from '../asset/img/ic/blueStar.svg';
import { fDate } from '../utils/formatTime';
import { statusNotice } from '../utils/statusCode';
import { useDispatch } from 'react-redux';
import { addFavorite, deleteFavorite } from '../store/slices/ilharu';
import {
  addFavoriteSurvey,
  deleteFavoriteSurvey
} from '../store/slices/survey';

const NoticeMentBox = ({ data, onClick }) => {
  const dispatch = useDispatch();
  const handleFavorite = (bool) => {
    switch (data?.type) {
      case 'notice':
        if (bool) {
          return dispatch(deleteFavorite(data?._id));
        } else {
          return dispatch(addFavorite(data?._id));
        }
      default:
        if (bool) {
          return dispatch(deleteFavoriteSurvey(data?._id));
        } else {
          return dispatch(addFavoriteSurvey(data?._id));
        }
    }
  };

  useEffect(() => {}, [dispatch]);

  return (
    <TopBox className="flex flex-col gap-2 mb-2 ">
      <div className="flex justify-between">
        <div className="text-18 text-text1 font-bold" onClick={onClick}>
          {statusNotice[data?.type]}
        </div>
        <div className="relative z-10">
          {data?.isAdminPriority ? (
            <img
              src={blueStar}
              alt="이미지"
              // onClick={() => handleFavorite(data?.isFavorite)}
            />
          ) : (
            <img
              src={data?.isFavorite ? yellowStar : grayStar}
              alt="이미지"
              onClick={() => handleFavorite(data?.isFavorite)}
            />
          )}
        </div>
      </div>
      <div className="text-text2 font-semibold" onClick={onClick}>
        {data?.title}
      </div>
      <div className="inline-flex flex-row justify-between" onClick={onClick}>
        <div className="text-14 text-text5 font-normal mt-[3px]">
          {fDate(data?.createdAt)}
        </div>
        <div>
          <img src={arrow} alt="화살표" />
        </div>
      </div>
    </TopBox>
  );
};

export default NoticeMentBox;
