import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { useCRUD } from '../hooks/useCRUD';
import { updatePassword } from '../store/slices/user';

import { styled } from 'styled-components';
import BackHeader from '../component/BackHeader';
import BasicModal from '../component/Modal';
import { Input, Layout } from '../component/Styled';

// 토스트
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Button = styled.button`
  width: 328px;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  border-radius: 8px;
  text-align: center;
  padding: 13px;
  cursor: pointer;
  font-weight: 700;
  margin: 48px auto 0;
`;

const PasswordChage = () => {
  const { handleCreate } = useCRUD();
  const navigate = useNavigate();
  const [visible, setVisible] = useState({
    new: false,
    confirm: false
  });

  const handleVisible = (check) => {
    if (check === 'new') {
      setVisible({ ...visible, new: !visible.new });
    } else {
      setVisible({ ...visible, confirm: !visible.confirm });
    }
  };
  const passwordSchema = Yup.object().shape({
    newPassword: Yup.string()
      .required('새 비밀번호를 입력하세요')
      .min(8, '비밀번호는 최소 8자 이상이어야 합니다'),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], '비밀번호가 일치하지 않습니다')
      .required('비밀번호 확인을 입력하세요')
  });
  const formik = useFormik({
    validationSchema: passwordSchema,
    initialValues: {
      newPassword: '',
      confirmNewPassword: ''
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log('values', values);

      const res = await handleCreate(updatePassword, values);

      if (res) {
        toast('비밀번호 변경 성공');
        setTimeout(() => navigate(-1), 1000);
      }

      try {
      } catch (error) {
        const { response } = error;
        const afterSubmit = response.data.response.message;
        setErrors({ afterSubmit });
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Layout>
          <BackHeader />
          <BasicModal />
          <ToastContainer
            position="top-right" // 알람 위치 지정
            autoClose={500} // 자동 off 시
          />
          <div className="mt-10 mb-[389px] text-28 font-semibold text-text1 tracking-[-0.56px]">
            비밀번호 변경
          </div>
          <div className="relative w-324 block mx-auto">
            <Input
              className="mx-auto block"
              placeholder="변경할 비밀번호 입력"
              type={visible.new ? 'text' : 'password'}
              error={Boolean(touched.newPassword && errors.newPassword)}
              {...getFieldProps('newPassword')}
            />
            <div
              className="absolute right-0 top-[15px]"
              onClick={() => handleVisible('new')}>
              {visible.new ? <AiFillEye /> : <AiFillEyeInvisible />}
            </div>
            <div className="text-error text-13 ">
              {touched.newPassword && errors.newPassword}
            </div>
          </div>

          <div className="relative w-324 block mx-auto">
            <Input
              className="mt-4 block mx-auto"
              type={visible.confirm ? 'text' : 'password'}
              placeholder="변경할 비밀번호 재입력"
              {...getFieldProps('confirmNewPassword')}
              error={Boolean(
                touched.confirmNewPassword && errors.confirmNewPassword
              )}
            />
            <div
              className="absolute right-0 top-[15px]"
              onClick={handleVisible}>
              {visible.confirm ? <AiFillEye /> : <AiFillEyeInvisible />}
            </div>
            <div className="text-error text-13">
              {touched.confirmNewPassword && errors.confirmNewPassword}
            </div>
          </div>

          <Button className="text-main2 block" color="#0F62FE" type="submit">
            비밀번호 변경하기
          </Button>
        </Layout>
      </Form>
    </FormikProvider>
  );
};

export default PasswordChage;
