import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { LayoutBgGray } from '../component/Styled';
import { getCenter } from '../store/slices/ilharu';
import { isEmpty } from 'lodash';

// 컴포넌트
import BackHeader from '../component/BackHeader';
import CenterBox from '../component/CenterBox';

const Center = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { centers } = useSelector((state) => state.ilharu);

  useEffect(() => {
    dispatch(getCenter());
  }, []);

  return (
    <LayoutBgGray>
      <BackHeader ment={'네트워크 기관 소개'} />
      {isEmpty(centers) ? (
        <div className="text-center">기관이 없습니다.</div>
      ) : (
        centers.map((center, _idx) => (
          <CenterBox
            key={_idx}
            data={center}
            onClick={() =>
              navigate(`/centerDetail/${center?._id}`, { state: center })
            }
          />
        ))
      )}
    </LayoutBgGray>
  );
};

export default Center;
