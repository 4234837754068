import React from 'react';
import { useNavigate } from 'react-router-dom';
import back from '../asset/img/ic/back.svg';
import home from '../asset/img/ic/home.svg';

const BackHeader = ({ ment, url = -1, addStyle, main = false }) => {
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-baseline">
      <div
        style={{
          marginLeft: addStyle && '10px',
          marginBottom: addStyle && '15px'
        }}
        className={`cursor-pointer pt-4 flex gap-4 mb-8 text-text1 text-18 font-bold tracking-[-0.036px] `}>
        <img src={back} alt="뒤로가기" onClick={() => navigate(url)} />
        {ment}
      </div>
      {main && (
        <div className="cursor-pointer">
          <img src={home} alt="메인가기" onClick={() => navigate('/main')} />
        </div>
      )}
    </div>
  );
};

export default BackHeader;
