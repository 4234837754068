import React, { useRef } from 'react';
import BackHeader from '../component/BackHeader';
import { styled } from 'styled-components';
import { useSelector } from 'react-redux';

const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  margin: 0 auto;
  position: relative;
`;

const MyTypeCheck = () => {
  const { myProfile } = useSelector((state) => state.user);
  const iframeRef = useRef(null);

  const sendUUId = () => {
    if (iframeRef.current) {
      const uuid = myProfile?.uuid;
      const smtiExist = myProfile?.smtiExist;
      window.localStorage.setItem('uuid', uuid);
      const accessToken = localStorage.getItem('accessToken');
      iframeRef.current.contentWindow.postMessage(
        { accessToken, uuid, smtiExist },
        'https://www.salti.kr/ilharu'
      );
    }
  };

  return (
    <Layout className="relative">
      <BackHeader ment={'내 유형 결과 알아보기'} addStyle={true} main={true} />
      <iframe
        src="https://www.salti.kr/ilharu"
        title="salti"
        width="100%"
        height="800"
        ref={iframeRef}
        allowFullScreen
        onLoad={sendUUId}></iframe>
      {/* <div className="flex flex-col items-center gap-3 left-0 right-0 top-[40%] mx-auto absolute">
          <div>아직 유형 검사를 하지 않으셨네요.</div>
          <Button>
            <a href="https://www.salti.kr/illharu" target="_blank" rel="noopener noreferrer">
              내 유형 알아보기
            </a>
          </Button>
        </div> */}
    </Layout>
  );
};

export default MyTypeCheck;
