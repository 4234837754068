const { styled } = require('styled-components');

export const Layout = styled.div`
  max-width: 500px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  position: relative;
  padding: 0 18px;
`;

export const BgWhite = styled.div`
  max-width: 500px;
  width: 100%;
  background: #fff;
  margin: 0 auto;
`;

export const LayoutBgGray = styled.div`
  max-width: 500px;
  width: 100%;
  height: 1300px !important;
  max-height: 1500px;
  margin: 0 auto;
  position: relative;
  background: #f8f8f8;
  padding: 0 18px;
  @media (max-width: 768px) {
    padding-bottom: 16px;
  }
`;

export const Input = styled.input`
  width: 328px;
  padding: 12px 2px;
  align-items: center;
  border-bottom: 1px solid #c6c6c6;
`;

export const Button = styled.button`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  border-radius: 8px;
  text-align: center;
  padding: 13px 0px;
  cursor: pointer;
  font-weight: 700;
`;

export const AbsoluteButton = styled.button`
  width: 328px;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  border-radius: 8px;
  text-align: center;
  padding: 13px;
  cursor: pointer;
  position: absolute;
  left: 0;
  right: 0;
  margin: 48px auto;
  font-weight: 700;
`;

export const FixedBottomButton = styled.button`
  max-width: 328px;
  width: 100%;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  border-radius: 8px;
  text-align: center;
  padding: 13px;
  cursor: pointer;
  position: fixed;
  bottom: 18px;
  margin: 0px auto;
  font-weight: 700;
`;

export const TopBox = styled.div`
  width: 100%;
  padding: 16px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(202, 208, 211, 0.3);
`;

export const BlueBox = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 16px 12px;
  gap: 8px;
  border-radius: 10px;
  background: #dae8ff;
  box-shadow: 0px 0px 10px 0px rgba(202, 208, 211, 0.3);
  cursor: pointer;
  word-break: break-all;
`;

export const WhiteBox = styled.div`
  width: 100%;
  height: 100px;
  padding: 15px 12px;
  margin-top: 16px;
  align-self: stretch;
  border-radius: 10px;
  background: #fff;
`;

export const Tag = styled.div`
  display: flex;
  height: 29px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  background: ${(props) => props.color || '#E3EEFF'};
  color: ${(props) => props.textColor || '#000'};
  font-size: 11px;
  white-space: nowrap;
`;

export const CommentTag = styled.div`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 29px;
  padding: 8px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 30px;
  background: ${(props) => props.color || '#E3EEFF'};
  font-size: 13px;
`;

export const Card = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 10px 0px rgba(202, 208, 211, 0.3);
  margin: 16px auto 0;
`;

export const WriteButton = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: ${(props) => props.width || '116px'};
  height: ${(props) => props.height || '38px'};
  border-radius: 8px;
  border: 1px solid #5f94fb;
  background: #fff;
  cursor: pointer;
`;

export const Paper = styled.textarea`
  display: flex;
  width: 100%;
  height: 150px;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  background: #fff;
`;

export const NavyButton = styled.div`
  width: 160px;
  height: 100px;
  display: inline-flex;
  text-align: center;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 13px 19px 13px 17px;
  background: rgba(0, 29, 108, 0.8);
  border-radius: 20px;
  gap: 8px;
  box-shadow: 0px 0px 12px 0px rgba(14, 39, 105, 0.1);
  color: #fff;
  // font-family: yg-jalnan;
`;

export const BlueButton = styled.button`
  width: 160px;
  height: 44px;
  padding: 12px 8px 12px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: var(--brand-color-main, #0054f7);
  box-shadow: 0px 0px 10px 0px rgba(15, 98, 254, 0.2);
  color: #fff;
  line-height: 116%; /* 18.56px */
  letter-spacing: 0.16px;
`;

export const MainButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  border-radius: 10px;
  background: #dae8ff;
  cursor: pointer;
  box-shadow: 0px 0px 10px 0px rgba(202, 208, 211, 0.3);
`;

export const BorderBottom = styled.div`
  display: flex;
  height: 48px;
  padding: 14.5px 0px 13.5px 0px;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #97b7f6;
  font-size: 14px;
  cursor: pointer;
`;
