import React from 'react';
import { useLocation } from 'react-router-dom';

import BackHeader from '../component/BackHeader';
import { BlueBox, Card, LayoutBgGray } from '../component/Styled';

import sample from '../asset/img/sample3.png';
const MentorDetail = () => {
  const { state } = useLocation();

  return (
    <LayoutBgGray>
      {' '}
      <BackHeader ment={'일하루 멘토 상세 보기'} main={true} />
      <Card style={{ padding: '16px 12px' }}>
        <div className="flex pt-2 items-start w-full pb-4 gap-2 border-b border-solid border-[#e4e4e4]">
          <div className="w-80 h-80 ">
            <img
              className="rounded-md"
              src={state?.introImage?.url || sample}
              alt="멘토"
            />
          </div>
          <div>
            <div className="text-18 text-text1 font-bold">
              {state?.nickname}
            </div>
            <div className="text-text2">{state?.category?.title}</div>
            <div className="flex flex-row justify-between">
              <div className="text-12 text-text5  mt-[3px]">
                {state?.memo || '내용이 없습니다.'}
              </div>
            </div>
          </div>
        </div>
        <div className="text-14 text-text3 whitespace-pre-wrap mt-6">
          {state?.teacherAbout || '내용이 없습니다.'}
        </div>
        <div>
          <img
            className="w-full pt-4"
            src={state?.profileImage?.url}
            alt="이미지"
          />
        </div>
      </Card>
      <BlueBox style={{ padding: '10px' }}>
        <div className="text-14 text-text3 mb-3">
          일대일 멘토 매칭이 필요한 경우 일하루 담당자에게 연락 부탁드립니다.
        </div>
        <div className="text-14 text-text1 font-semibold">
          일하루 담당자: 김슬기 031-729-9171
        </div>
      </BlueBox>
    </LayoutBgGray>
  );
};

export default MentorDetail;
