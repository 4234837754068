import React from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useCRUD } from '../hooks/useCRUD';
import { updateProfile } from '../store/slices/user';

import { styled } from 'styled-components';
import { Input, Layout } from '../component/Styled';
import BackHeader from '../component/BackHeader';
import BasicModal from '../component/Modal';

// toast
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Button = styled.button`
  width: 328px;
  justify-content: center;
  align-items: center;
  background: ${(props) => props.color};
  border-radius: 8px;
  text-align: center;
  padding: 13px;
  cursor: pointer;
  font-weight: 700;
  margin: 48px auto 0;
`;

const ProfileChange = () => {
  const navigate = useNavigate();
  const { handleCreate } = useCRUD();
  const profileSchema = Yup.object().shape({
    nickname: Yup.string().required('닉네임을 입력해주세요.')
  });
  const formik = useFormik({
    validationSchema: profileSchema,
    initialValues: {
      nickname: ''
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      console.log('values', values);

      const res = await handleCreate(updateProfile, values);

      if (res) {
        toast('프로필 변경 성공');
        setTimeout(() => navigate(-1), 1000);
      }

      try {
      } catch (error) {
        const { response } = error;
        const afterSubmit = response.data.response.message;
        setErrors({ afterSubmit });
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Layout>
          <BackHeader />
          <BasicModal />
          <ToastContainer
            position="top-right" // 알람 위치 지정
            autoClose={500} // 자동 off 시
          />
          <div className="mt-10 mb-[389px] text-28 font-semibold text-text1 tracking-[-0.56px]">
            프로필 정보 변경
          </div>

          <Input
            className="block mx-auto"
            placeholder="닉네임 입력"
            error={Boolean(touched.nickname && errors.nickname)}
            {...getFieldProps('nickname')}
          />
          <div className="text-error text-13">
            {touched.nickname && errors.nickname}
          </div>

          <Button className="text-main2 block" color="#0F62FE" type="submit">
            프로필 정보 변경하기
          </Button>
        </Layout>
      </Form>
    </FormikProvider>
  );
};

export default ProfileChange;
