import dream1 from '../asset/img/dream1.svg';
import dream2 from '../asset/img/dream2.svg';
import dream3 from '../asset/img/dream3.svg';
import dream4 from '../asset/img/dream4.svg';
import ilharu1 from '../asset/img/ilharu1.svg';
import ilharu2 from '../asset/img/ilharu2.svg';
import ilharu3 from '../asset/img/ilharu3.svg';
import ilharu4 from '../asset/img/ilharu4.svg';
import ilharu5 from '../asset/img/ilharu5.svg';
import center1 from '../asset/img/center1.svg';
import center2 from '../asset/img/center2.svg';
import center3 from '../asset/img/center3.svg';
import center4 from '../asset/img/center4.svg';
import center5 from '../asset/img/center5.svg';
import check from '../asset/img/ic/check.webp';
import glasses from '../asset/img/ic/glasses.webp';
import pencil from '../asset/img/ic/pencil.webp';
import again from '../asset/img/ic/again.webp';

export const service = [
  { text: '진로 직업 체험', value: 'careerJobExperience' },
  { text: '멘토링', value: 'mentoring' },
  { text: '직장 체험', value: 'workplaceExperience' },
  { text: '자립 기술 훈련', value: 'independenceSkillTraining' },
  { text: '자격증 취득', value: 'certificationAcquisition' },
  { text: '직업훈련', value: 'jobTraining' }
];

// 소개페이지 사용

export const imageDream = [
  { src: dream1 },
  { src: dream2 },
  { src: dream3 },
  { src: dream4 }
];

export const imageIlharu = [
  { src: ilharu1 },
  { src: ilharu2 },
  { src: ilharu3 },
  { src: ilharu4 },
  { src: ilharu5 }
];

export const imageCenter = [
  { src: center1 },
  { src: center2 },
  { src: center3 },
  { src: center4 },
  { src: center5 }
];

// 메인페이지 사용
export const btnMent = [
  {
    img: check,
    ment: `일하루 멘토\n 확인하기`,
    address: '/mentorList'
  },
  {
    img: glasses,
    ment: `네트워크 기관\n찾아보기`,
    address: '/center'
  },
  {
    img: again,
    ment: `내 유형결과\n알아보기`,
    address: '/typeCheck'
  },
  {
    img: pencil,
    ment: `자립계획서\n작성하기`,
    address: '/selfPlan'
  }
];
