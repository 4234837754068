import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { cleanObject, headers, urls } from '../../libs/reqConf';
import { getOpen } from './modal';
import {
  checkAnonymousTokenAndRefresh,
  checkTokenAndRefresh
} from '../../utils/function';
import { getNotice } from './ilharu';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  survey: {},
  selectedSurvey: {}
};

const slice = createSlice({
  name: 'survey',
  initialState,
  reducers: {
    handleDialog(state, action) {
      state.dialog = action.payload;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      console.log('hasError', action.payload);
      state.isLoading = false;
      state.error = action.payload;
    },

    // UPDATE Review
    getSurveySuccess(state, action) {
      console.log('getSurveySuccess action', action.payload);
      state.isLoading = false;
      state.survey = action.payload;
    },

    // 설문 상세보기
    getSurveyDetailSuccess(state, action) {
      state.isLoading = false;
      state.selectedSurvey = action.payload;
    },

    // 설문조사 작성
    getSurveyAnswerSuccess(state, action) {
      state.isLoading = false;
    },

    // OPEN MODAL
    openModal(state) {
      state.isOpenModal = true;
    },

    // CLOSE MODAL
    closeModal(state) {
      state.isOpenModal = false;
      state.selectedEventId = null;
      state.selectedRange = null;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModal, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function handleForm(status) {
  return async (dispatch) => {
    try {
      dispatch(slice.actions.handleDialog(status));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// 설문조사 조회
export function getSurvey() {
  return async (dispatch) => {
    try {
      const anonymousToken = await checkAnonymousTokenAndRefresh(dispatch);
      const url = `${urls.user}/survey/list`;
      const config = { headers: headers(anonymousToken) };
      const response = await axios.get(url, config);
      console.log('getSurvey response', response.data.data);
      dispatch(slice.actions.getSurveySuccess(response.data.data));
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// 설문조사 조회
export function getSurveyDetail(surveyId) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/survey/${surveyId}`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getSurveyDetailSuccess(response.data.data));
      return response.data.data?.questions;
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSurveyAnswer(surveyId, params) {
  return async (dispatch) => {
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/survey/answer/${surveyId}`;
      const body = { answers: params };
      const config = { headers: headers(accessToken) };
      console.log('body', body);
      const response = await axios.post(url, body, config);
      console.log('response', response.data.data);
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSurveyRead(surveyId, params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/survey/read/${surveyId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);
      dispatch(slice.actions.getSurveyReadSuccess(response.data.data));
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
// 공지사항 추가
export function addFavoriteSurvey(surveyId) {
  console.log('noticeInd', surveyId);
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/survey/favorite/${surveyId}`;
      const config = { headers: headers(accessToken) };
      await axios.post(url, {}, config);
      dispatch(getNotice());
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
// 공지사항 삭제
export function deleteFavoriteSurvey(surveyId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const accessToken = await checkTokenAndRefresh(dispatch);
      const url = `${urls.user}/survey/favorite/${surveyId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(getNotice());
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// 익명설문조사 조회
export function getAnonymousSurveyDetail(surveyId) {
  return async (dispatch) => {
    try {
      const anonymousToken = await checkAnonymousTokenAndRefresh(dispatch);
      const url = `${urls.user}/survey/${surveyId}`;
      const config = { headers: headers(anonymousToken) };
      const response = await axios.get(url, config);

      dispatch(slice.actions.getSurveyDetailSuccess(response.data.data));
      return response.data.data?.questions;
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------
// 익명설문조사 전송
export function getAnonymousSurveyAnswer(surveyId, params) {
  return async (dispatch) => {
    try {
      const anonymousToken = await checkAnonymousTokenAndRefresh(dispatch);
      const url = `${urls.user}/survey/answer/${surveyId}`;
      const body = { answers: params };
      const config = { headers: headers(anonymousToken) };
      console.log('body', body);
      const response = await axios.post(url, body, config);
      console.log('response', response.data.data);
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}
