import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// utils
import { Stack, TextField, MenuItem } from '@mui/material';
import { isDate, removeLeadingZero } from '../utils/function';
import { fDate } from '../utils/formatTime';

DateTimePicker.propTypes = {
  label: PropTypes.string,
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  minDateTime: PropTypes.instanceOf(Date),
  errorLabel: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object
};

DateTimePicker.defaultProps = {
  label: '날짜 및 시간',
  value: new Date(),
  onChange: () => {},
  errorLabel: '',
  touched: {},
  errors: {}
};

function DateTimePicker({
  label,
  value,
  onChange,
  minDateTime,
  errorLabel,
  touched,
  errors
}) {
  const defaultValue = fDate(new Date()) + 'T09:00';
  const initialValue = isDate(value) ? new Date(value) : new Date(defaultValue);

  const minDate = isDate(minDateTime)
    ? new Date(minDateTime).toISOString().split('T')[0]
    : null;
  const minHour = isDate(minDateTime)
    ? ('0' + new Date(minDateTime).getHours()).slice(-2)
    : null;
  const minMinute = isDate(minDateTime)
    ? ('0' + new Date(minDateTime).getMinutes()).slice(-2)
    : null;

  const [date, setDate] = useState(initialValue.toISOString().split('T')[0]);
  const [hour, setHour] = useState(('0' + initialValue.getHours()).slice(-2));

  const [minute, setMinute] = useState(
    ('0' + initialValue.getMinutes()).slice(-2)
  );

  useEffect(() => {
    setDate(fDate(value));
    setHour(('0' + initialValue.getHours()).slice(-2));
    setMinute(('0' + initialValue.getMinutes()).slice(-2));
  }, [initialValue]);

  const hours = Array.from({ length: 24 }, (_, i) =>
    i < 10 ? `0${i}` : `${i}`
  );
  const minutes = ['00', '15', '30', '45'];

  const minHourFilter = (hour) => date !== minDate || hour >= minHour;
  const minMinuteFilter = (minute) =>
    date !== minDate || hour !== minHour || minute >= minMinute;

  // 날짜 업데이트
  const handleDateChange = (event) => {
    const newDate = event.target.value;

    setDate(newDate);
    handleChange(newDate, hour, minute);
  };

  // 시간 업데이트
  const handleHourChange = (event) => {
    const newHour = event.target.value;

    console.log('날짜', date);
    setHour(newHour);
    handleChange(date, newHour, minute);
  };

  // 분 업데이트
  const handleMinuteChange = (event) => {
    const newMinute = event.target.value;
    setMinute(newMinute);
    handleChange(date, hour, newMinute);
  };

  // 날짜 데이터 전체 업데이트
  const handleChange = (newDate, newHour, newMinute) => {
    console.log('newDate', newDate);
    const finalDate = new Date(`${newDate}T${newHour}:${newMinute}`);
    console.log('finalDate', finalDate);
    onChange(finalDate.toISOString());
  };

  return (
    <Stack direction="column" spacing={2} sx={{ mt: 3, mb: 1, width: '100%' }}>
      <TextField
        size="small"
        sx={{ marginBottom: '16px' }}
        type="date"
        fullWidth
        label={label}
        value={date}
        onChange={handleDateChange}
        inputProps={{ min: minDate }}
        error={Boolean(touched[errorLabel] && errors[errorLabel])}
        helperText={touched[errorLabel] && errors[errorLabel]}
        // disabled
      />
      <Stack direction="row" spacing={1} fullWidth>
        <TextField
          size="small"
          sx={{ marginBottom: '16px' }}
          select
          fullWidth
          label="시"
          value={hour}
          onChange={handleHourChange}
          SelectProps={{
            MenuProps: { PaperProps: { style: { maxHeight: 210, width: 100 } } }
          }}>
          {hours.filter(minHourFilter).map((hour) => (
            <MenuItem key={hour} value={hour}>
              {removeLeadingZero(hour)}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          size="small"
          sx={{ marginBottom: '16px' }}
          select
          fullWidth
          label="분"
          value={minute}
          onChange={handleMinuteChange}>
          {minutes.filter(minMinuteFilter).map((minute) => (
            <MenuItem key={minute} value={minute}>
              {removeLeadingZero(minute)}
            </MenuItem>
          ))}
        </TextField>
      </Stack>
    </Stack>
  );
}

export default DateTimePicker;
