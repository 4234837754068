import { useState, useEffect } from 'react';

function usePaging(initialPage) {
  const [page, setPage] = useState(initialPage);
  const [pageNumber, setPageNumber] = useState(initialPage);
  const itemsPerPage = 4; // 페이지당 보여줄 항목 수를 설정하세요.

  let startIndex = (page - 1) * itemsPerPage;
  let endIndex = startIndex + itemsPerPage;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setPageNumber(newPage);
    localStorage.setItem('pageNumber', newPage);
  };

  useEffect(() => {
    const pageNumber = localStorage.getItem('pageNumber');
    if (pageNumber) {
      setPage(Number(pageNumber));
    }
  }, []);

  return {
    page,
    pageNumber,
    startIndex,
    endIndex,
    itemsPerPage,
    handleChangePage,
    setPage
  };
}

export default usePaging;
