import React, { useMemo } from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import {
  Home,
  Introduce,
  Scheduler,
  Login,
  MentorDetail,
  ProfileChange,
  IdPwFind,
  Main,
  Metaverse,
  Enrolintro,
  ActivityHistory,
  SurveyDetail,
  SurveyDetailPublic,
  MyTypeCheck,
  Center,
  MentorList,
  Mypage,
  SchedulerDetail,
  ReviewWrite,
  Notice,
  SelfPlan,
  SelfPlanWrite,
  CenterDetail,
  Survey
} from '../src/page';
import { useSelector } from 'react-redux';
import AuthLayOut from './component/AuthLayOut';
import JournalWrite from './page/JournalWrite';
import NoticeDetail from './page/NoticeDetail';
import PasswordChage from './page/PasswordChange';
import Loading from './component/Loading';

function App() {
  const { isLogin } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);
  const ilharu = useSelector((state) => state.ilharu);
  const schedule = useSelector((state) => state.schedule);
  const selfPlan = useSelector((state) => state.selfPlan);
  const survey = useSelector((state) => state.survey);

  const isLoading = useMemo(() => {
    return (
      user?.isLoading ||
      ilharu?.isLoading ||
      schedule?.isLoading ||
      selfPlan?.isLoading ||
      survey?.isLoading
    );
  }, [user, ilharu, schedule, selfPlan, survey]);

  const accessToken = localStorage.getItem('accessToken');

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/loading" element={<Loading />} />
        <Route path="/" element={<Home />} />
        <Route path="/introduce" element={<Introduce />} />
        <Route element={<AuthLayOut />}>
          <Route path="/login" element={<Login />} />
        </Route>
        <Route path="/survey" element={<Survey />} />
        <Route path="/surveyDetail/:id" element={<SurveyDetail />} />
        <Route
          path="/surveyDetail/public/:id"
          element={<SurveyDetailPublic />}
        />

        {/* 사용자가 로그인되지 않았을 때 로그인 페이지로 리디렉션 */}
        {!(isLogin && accessToken) && (
          <Route path="*" element={<Navigate replace to="/" />} />
        )}
        <Route path="/find" element={<IdPwFind />} />
        <Route path="/enrollintro" element={<Enrolintro />} />
        {isLogin && accessToken !== null && (
          <>
            <Route path="/main" element={<Main isLoading={isLoading} />} />
            <Route path="/scheduler" element={<Scheduler />} />
            <Route path="/metaverse" element={<Metaverse />} />
            <Route path="/activityHistory" element={<ActivityHistory />} />

            <Route path="/typeCheck" element={<MyTypeCheck />} />
            <Route path="/center" element={<Center />} />
            <Route path="/centerDetail/:id" element={<CenterDetail />} />
            <Route path="/mentorList" element={<MentorList />} />
            <Route path="/mentorDetail/:id" element={<MentorDetail />} />
            <Route path="/myPage" element={<Mypage />} />
            <Route path="/profile" element={<ProfileChange />} />
            <Route path="/passwordChange" element={<PasswordChage />} />

            <Route path="/schedulerDetail/:id" element={<SchedulerDetail />} />
            <Route path="/reviewWrite" element={<ReviewWrite />} />
            <Route path="/journalWrite" element={<JournalWrite />} />
            <Route path="/journalUpdate/:id" element={<JournalWrite />} />
            <Route path="/notice" element={<Notice />} />
            <Route path="/noticeDetail/:id" element={<NoticeDetail />} />
            <Route path="/selfPlan" element={<SelfPlan />} />
            <Route path="/selfPlanWrite" element={<SelfPlanWrite />} />
            <Route path="/selfPlanDetail/:id" element={<SelfPlanWrite />} />
          </>
        )}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
