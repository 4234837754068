import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Pagination } from '@mui/material';
import { getNotice } from '../store/slices/ilharu';
import usePaging from '../hooks/usePaging';

//컴포넌트
import { LayoutBgGray } from '../component/Styled';
import BackHeader from '../component/BackHeader';
import NoticeMentBox from '../component/NoticeMentBox';

const Notice = () => {
  const navigate = useNavigate();
  const { notices } = useSelector((state) => state.ilharu);

  const dispatch = useDispatch();
  const { page, itemsPerPage, handleChangePage, startIndex, endIndex } =
    usePaging(1);
  useEffect(() => {
    dispatch(getNotice());
  }, [dispatch]);
  useEffect(() => {
    // 페이지 초기화용
    localStorage.setItem('pageNumber', 1);
  }, []);

  const displayedNotices = notices.slice(startIndex, endIndex);

  const handleNavigate = (notice) => {
    if (notice?.type === 'notice') {
      navigate(`/noticeDetail/${notice?._id}`, { state: notice });
    } else {
      navigate(`/surveyDetail/${notice?._id}`, { state: notice });
    }
  };

  return (
    <LayoutBgGray>
      <BackHeader ment={'일하루 소식알림'} />
      {notices?.length === 0 && (
        <div className="text-center">소식이 없습니다.</div>
      )}
      {displayedNotices.map((notice, idx) => (
        <NoticeMentBox
          key={idx}
          data={notice}
          onClick={() => handleNavigate(notice)}
        />
      ))}

      {displayedNotices?.length !== 0 && (
        <Pagination
          count={Math.ceil(notices.length / itemsPerPage)}
          page={Number(page)}
          onChange={handleChangePage}
          color="primary"
        />
      )}
    </LayoutBgGray>
  );
};

export default Notice;
