// slice

import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, PERSIST, PURGE } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import userReducer from './slices/user';
import selfPlanReducer from './slices/selfPlan';
import modalReducer from './slices/modal';
import scheduleReducer from './slices/schedule';
import ilharuReducer from './slices/ilharu';
import surveyReducer from './slices/survey';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['schedule', 'user', 'selfPlan', 'ilharu', 'survey']
};

const rootReducer = combineReducers({
  user: userReducer,
  selfPlan: selfPlanReducer,
  modal: modalReducer,
  schedule: scheduleReducer,
  ilharu: ilharuReducer,
  survey: surveyReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [PERSIST, PURGE]
      }
    })
});

export const persistor = persistStore(store);
export default store;
