export const statusPreferredService = {
  workplaceExperience: '직장 체험',
  careerJobExperience: '진로 직업 체험',
  mentoring: '멘토링',
  jobTraining: '직업 훈련',
  independenceSkillTraining: '자립 기술 훈련',
  certificationAcquisition: '자격증 취득'
};

export const statusSchedules = {
  active: { text: '활성', color: '#2EDA3C' },
  inactive: { text: '비활성', color: '#E4E4E4' },
  delete: { text: '삭제', color: '#ff0000' },
  pending: { text: '대기중', color: '#EAC132' },
  progress: { text: '진행중', color: '#2EDA3C', textColor: '#fff' },
  needReview: { text: '소감 작성필요', color: '#0F62FE', textColor: '#fff' },
  complete: { text: '일정 종료', color: '#E4E4E4' }
};

export const statusActivity = {
  active: { text: '활성', color: '#2EDA3C' },
  inactive: { text: '비활성', color: '#E4E4E4' },
  delete: { text: '삭제', color: '#ff0000' },
  pending: { text: '대기중', color: '#EAC132' },
  progress: { text: '진행중', color: '#2EDA3C', textColor: '#fff' },
  needReview: { text: '소감 작성필요', color: '#0F62FE', textColor: '#fff' },
  complete: { text: '일정 종료', color: '#E4E4E4' },
  existComment: { text: '선생님 코멘트', color: '' }
};

export const statusBooth = {
  market: '마켓',
  cafe: '카페',
  handmade: '핸드메이드',
  mediaStudio: '비디오 스튜디오',
  activity: '활동',
  live: '라이브',
  exhibition: '전시'
};

export const statusNotice = {
  notice: '[공지사항]',
  survey: '[설문]',
  schedule: '[일정]'
};
