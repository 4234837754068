// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.react-datepicker__input-container .react-datepicker__calendar-icon {
  position: absolute;
  padding: 0;
  top: 11px;
  left: 110px;
}

.react-datepicker-wrapper {
  border-radius: 8px;
  border: 1px solid #e4e4e4;
  background: #fff;
}

.react-datepicker__view-calendar-icon input {
  width: 144px;
  padding: 8px 16px;
}
`, "",{"version":3,"sources":["webpack://./src/page/date.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,SAAS;EACT,WAAW;AACb;;AAEA;EACE,kBAAkB;EAClB,yBAAyB;EACzB,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".react-datepicker__input-container .react-datepicker__calendar-icon {\n  position: absolute;\n  padding: 0;\n  top: 11px;\n  left: 110px;\n}\n\n.react-datepicker-wrapper {\n  border-radius: 8px;\n  border: 1px solid #e4e4e4;\n  background: #fff;\n}\n\n.react-datepicker__view-calendar-icon input {\n  width: 144px;\n  padding: 8px 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
