import React, { useEffect } from 'react';
import { Layout, Input, AbsoluteButton } from '../component/Styled';
import { useNavigate } from 'react-router-dom';
import blueArrow from '../asset/img/ic/blueArrow.svg';
import ImgComponent from '../component/ImgComponent';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { getLogin } from '../store/slices/user';
import { useDispatch } from 'react-redux';
import { getEvents } from '../store/slices/schedule';
import BackHeader from '../component/BackHeader';
import BasicModal from '../component/Modal';
const Login = () => {
  const handleGuestLogin = () => {
    const values = {
      studentID: 'guest',
      password: 'guest',
      remember: false
    };
    dispatch(getLogin(values));
  };

  useEffect(() => {
    getEvents();
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  // 로그인 검증
  const loginSchema = Yup.object().shape({
    studentID: Yup.string().required('아이디를 올바르게 해주세요.'),
    password: Yup.string().required('비밀번호를 올바르게 입력해주세요.')
  });
  const formik = useFormik({
    validationSchema: loginSchema,
    initialValues: {
      studentID: '',
      password: '',
      remember: false
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        dispatch(getLogin(values));
      } catch (error) {
        const { response } = error;
        const afterSubmit = response.data.response.message;
        setErrors({ afterSubmit });
      }
    }
  });
  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Layout>
          <BasicModal />
          <BackHeader url={'/'} />
          <div className="w-324 mx-auto">
            <div className="mt-20 mb-2 text-28 font-semibold text-text1 tracking-[-0.56px]">
              일하루 입장하기
            </div>
            <div className="mb-[226px] text-text2 font-normal tracking-[-0.24px]">
              로그인이 필요한 서비스입니다.
            </div>
          </div>

          <div className="w-324 mx-auto">
            <Input
              {...getFieldProps('studentID')}
              error={Boolean(touched.studentID && errors.studentID)}
              className="mb-2"
              placeholder="아이디 입력"
            />
            <div className="text-error text-13">
              {touched.studentID && errors.studentID}
            </div>
            <Input
              {...getFieldProps('password')}
              type="password"
              className="mb-2"
              error={Boolean(touched.password && errors.password)}
              placeholder="비밀번호 입력"
            />
            <div className="text-error text-13">
              {touched.password && errors.password}
            </div>
            <div className="flex flex-row mt-5 justify-between">
              <div className="flex items-stretch">
                <input
                  className="w-20 h-20 mt-px"
                  type="checkbox"
                  studentID="autoLogin"
                  onClick={() => setFieldValue('remember', !values?.remember)}
                />
                <label className="ml-2 text-text3" htmlFor="autoLogin">
                  자동 로그인
                </label>
              </div>

              <div
                className="underline text-text3 cursor-pointer"
                onClick={() => navigate('/find')}>
                아이디/비밀번호 찾기
              </div>
            </div>
            <div className="flex items-stretch mt-9 mb-2.5 ">
              <div
                className="text-text4 cursor-pointer"
                onClick={() => navigate('/enrollintro')}>
                일하루 청소년 이용안내
              </div>
              <ImgComponent className="mt-0.5" src={blueArrow} />
            </div>

            <div className="flex items-stretch">
              <div
                className="text-text4 cursor-pointer"
                onClick={handleGuestLogin}>
                게스트 로그인
              </div>
              <ImgComponent className="mt-0.5" src={blueArrow} />
            </div>
          </div>
          <AbsoluteButton
            type="submit"
            className="text-main2 mt-[48px] mx-auto"
            color="#0F62FE">
            입장하기
          </AbsoluteButton>
        </Layout>
      </Form>
    </FormikProvider>
  );
};

export default Login;
