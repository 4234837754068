const { useDispatch } = require('react-redux');

export const useCRUD = () => {
  const dispatch = useDispatch();

  const handleCreate = async (func, body) => {
    console.log('handleCreate body', body);
    try {
      const res = dispatch(func(body));
      console.log('res handleCreate', res);
      return res;
    } catch (error) {
      console.log('error', error.response);
    }
  };

  const handleUpdate = async (func, id, body) => {
    console.log('handleUpdate id', id);
    console.log('handleUpdate body', body);
    try {
      const res = dispatch(func(id, body));
      return res;
    } catch (error) {
      console.error(error.response);
    }
  };

  const handleDelete = async (func, id, ...params) => {
    try {
      dispatch(func(id));
    } catch (error) {
      console.log('error', error);
    }
  };

  return { handleCreate, handleUpdate, handleDelete };
};
