import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  LayoutBgGray,
  TopBox,
  BlueBox,
  WhiteBox,
  Tag
} from '../component/Styled';
import BackHeader from '../component/BackHeader';
import canlendar from '../asset/img/ic/calenderWhite.svg';
import usePaging from '../hooks/usePaging';
import { getActivity } from '../store/slices/ilharu';
import { statusNotice, statusActivity } from '../utils/statusCode';
import { fDate, fTime } from '../utils/formatTime';
import BasicModal from '../component/Modal';

import { Pagination } from '@mui/material';
const ActivityHistory = () => {
  const dispatch = useDispatch();
  const { activitys } = useSelector((state) => state.ilharu);

  const navigate = useNavigate();
  const {
    page,
    handleChangePage,
    itemsPerPage,
    startIndex,
    endIndex,
    setPage
  } = usePaging(1);
  const cate = localStorage.getItem('cate');
  const [selectedCate, setSelectedCate] = useState(cate || 'all');
  const [list, setList] = useState([
    { button: '전체', value: 'all', click: true },
    { button: '일정', value: 'schedule', click: false },
    { button: '설문', value: 'survey', click: false },
    { button: '공지사항', value: 'notice', click: false }
  ]);
  console.log('selectedCate', selectedCate);

  const handleClick = (idx, text) => {
    localStorage.setItem('cate', text);
    setSelectedCate(text);
    const newList = [...list];
    newList[idx]['click'] = true;
    setList([...newList]);
    if (idx === 1 || 2 || 3) {
      setPage(Number(1));
    }
  };

  useEffect(() => {
    dispatch(getActivity());
  }, []);
  const filterActivitys = activitys.filter(
    (activity) => activity?.type === selectedCate
  );

  // 카테고리 전체
  const displayedActivitys = activitys.slice(startIndex, endIndex);
  // 카테고리 전체 말고 다른거
  const displayedFilterActivitys = filterActivitys.slice(startIndex, endIndex);

  const handleNavigate = (data) => {
    //설문
    if (data?.type === 'survey') {
      navigate(`/surveyDetail/${data?._id}`, { state: data });

      //일정
    } else if (data?.type === 'schedule') {
      navigate(`/schedulerDetail/${data?._id}`, { state: { data: data } });

      //공지사항
    } else {
      navigate(`/noticeDetail/${data?._id}`, { state: data });
    }
  };

  return (
    <LayoutBgGray>
      <BackHeader ment="활동 내역" />
      {/* <TopBox className="inline-flex px-[50px] py-[16px] justify-center items-center">
        <div className="flex flex-row">
          <div>
            <img src={canlendar} alt="달력이미지" />
          </div>
          <div className="font-15 font-semibold text-text1 pt-px">
            2023.06.08 - 2023.07.07
          </div>
        </div>
      </TopBox> */}
      <BlueBox>
        <BasicModal title={'알림'} />
        <div className="flex gap-3 mb-4">
          {list.map((el, _idx) => (
            <div
              key={_idx}
              style={{ fontWeight: selectedCate === el.value && 700 }}
              onClick={() => handleClick(_idx, el.value)}>
              {el.button}
            </div>
          ))}
        </div>
        {selectedCate === 'all'
          ? displayedActivitys.map((el, _idx) => {
              return (
                <WhiteBox key={_idx} onClick={() => handleNavigate(el)}>
                  <div className="flex gap-2">
                    {el?.type === 'schedule' &&
                      el?.scheduleChips.length > 0 &&
                      el?.scheduleChips?.map((chip, num) => {
                        return (
                          <Tag
                            key={num}
                            color={statusActivity[chip]?.color}
                            textColor={statusActivity[chip]?.textColor}>
                            {statusActivity[chip]?.text}
                          </Tag>
                        );
                      })}
                    {el?.type === 'schedule' && el?.journalCount > 0 && (
                      <Tag>활동일지({el?.journalCount})</Tag>
                    )}
                  </div>
                  <div className="flex justify-between mt-2 px-1">
                    <div className="flex-1">
                      <div className="text-12 text-text3">
                        {statusNotice[el?.type]}
                      </div>
                      <div className="font-medium text-text1 break-keep">
                        {el?.title}
                      </div>
                    </div>
                    <div>
                      <div className="text-14 text-text8 font-medium">
                        {fDate(el.createdAt)}
                      </div>
                      <div className="text-14 text-text8 text-right font-medium">
                        {fTime(el.createdAt)}
                      </div>
                    </div>
                  </div>
                </WhiteBox>
              );
            })
          : displayedFilterActivitys.map((el, _idx) => {
              return (
                <WhiteBox key={_idx} onClick={() => handleNavigate(el)}>
                  <div className="flex gap-2">
                    {el?.type === 'schedule' &&
                      el?.scheduleChips.length > 0 &&
                      el?.scheduleChips?.map((chip, num) => {
                        return (
                          <Tag
                            key={num}
                            color={statusActivity[chip]?.color}
                            textColor={statusActivity[chip]?.textColor}>
                            {statusActivity[chip]?.text}
                          </Tag>
                        );
                      })}
                    {el?.type === 'schedule' && el?.journalCount > 0 && (
                      <Tag>활동일지({el?.journalCount})</Tag>
                    )}

                    {el?.type === 'survey' && el?.isComplete && (
                      <Tag color={'#E4E4E4'}>설문 완료</Tag>
                    )}
                  </div>
                  <div className="flex justify-between mt-2 px-1">
                    <div>
                      <div className="text-12 text-text3">
                        {statusNotice[el?.type]}
                      </div>
                      <div className="font-medium text-text1">{el?.title}</div>
                    </div>
                    <div>
                      <div className="text-14 text-text8 font-medium">
                        {fDate(el.createdAt)}
                      </div>
                      <div className="text-14 text-text8 text-right font-medium">
                        {fTime(el.createdAt)}
                      </div>
                    </div>
                  </div>
                </WhiteBox>
              );
            })}
      </BlueBox>
      {selectedCate === 'all' && displayedActivitys?.length !== 0 && (
        <Pagination
          count={Math.ceil(activitys.length / 4)}
          page={Number(page)}
          onChange={handleChangePage}
          color="primary"
        />
      )}
      {selectedCate !== 'all' && displayedFilterActivitys?.length !== 0 && (
        <Pagination
          count={Math.ceil(filterActivitys.length / itemsPerPage)}
          page={Number(page)}
          onChange={handleChangePage}
          color="primary"
        />
      )}
    </LayoutBgGray>
  );
};

export default ActivityHistory;
