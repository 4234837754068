import React from 'react';

const ImgComponent = ({ src, className }) => {
  return (
    <div>
      <img className={className} src={src} alt="이미지" />
    </div>
  );
};

export default ImgComponent;
