import React from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from 'styled-components';
import BackHeader from '../component/BackHeader';
import { Layout } from '../component/Styled';

const BlueBox = styled.div`
  padding: 8px 140px 8px 12px;
  align-items: center;
  border-radius: 8px;
  background: #e3eeff;
  margin-top: 16px;
`;

const CenterDetail = () => {
  const { state } = useLocation();

  return (
    <Layout>
      <BackHeader ment={'네트워크기관 상세정보'} main={true} />
      <div className="flex pt-2 flex-col items-start self-stretch gap-2 border-b border-solid border-[#e4e4e4]">
        <div className="text-text1 font-20 font-bold">
          {state?.organizationName}
        </div>
        <div className="mb-4">{state?.subtitle}</div>
      </div>
      <div className="text-14 text-text3 whitespace-pre-wrap mt-6">
        {state?.description}
      </div>
      <BlueBox style={{ padding: '10px' }}>
        <div className="text-14 text-text3 ">문의 및 담당자</div>
        <div className="text-14 text-text1 font-semibold">
          담당자: {state?.contactName} {state?.contactNumber}
        </div>
      </BlueBox>
      <div className="mt-4">
        {state?.introImage ? (
          <img width={'100%'} src={state?.introImage?.url} alt="샘플" />
        ) : (
          <div>이미지가 없습니다.</div>
        )}
      </div>
    </Layout>
  );
};

export default CenterDetail;
