import { filter } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';
import { cleanObject, headers, urls } from '../../libs/reqConf';
import { getOpen } from './modal';
import { checkTokenAndRefresh } from '../../utils/function';
import { PURGE } from 'redux-persist';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  selfPlans: [],

  selectSelfPlan: {},
  isErrorModal: false,
  dialog: false,
  selectedEventId: null,
  selectedRange: null
};

const slice = createSlice({
  name: 'selfPlan',
  initialState,
  reducers: {
    handleDialog(state, action) {
      console.log('selfPlan action.payload', action.payload);
      state.dialog = action.payload;
    },
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET EVENTS
    getSelfPlanSuccess(state, action) {
      state.isLoading = false;
      state.selfPlans = action.payload;
    },

    // CREATE EVENT
    createSelfPlanSuccess(state, action) {
      const newPlan = action.payload;
      state.isLoading = false;
      state.selfPlans = [...state.selfPlans, newPlan];
    },

    selectSelfPlanSuccess(state, action) {
      state.isLoading = false;
      state.selectSelfPlan = action.payload;
    },

    updateSelfPlanSuccess(state, action) {
      state.isLoading = false;
      state.selfPlans = state.selfPlans.map((_selfPlan) => {
        return _selfPlan._id === action.payload._id
          ? action.payload
          : _selfPlan;
      });
    },

    // DELETE EVENT
    deleteSelfPlanSuccess(state, action) {
      const { planId } = action.payload;
      const deleteSelfPlan = filter(
        state.selfPlans,
        (plan) => plan.id !== planId
      );
      state.isLoading = false;
      state.selfPlans = deleteSelfPlan;
    },

    // SELECT EVENT
    selectEvent(state, action) {
      const planId = action.payload;
      state.isOpenModal = true;
      state.selectedEventId = planId;
    },

    // SELECT RANGE
    selectRange(state, action) {
      const { start, end } = action.payload;
      state.isOpenModal = true;
      state.selectedRange = { start, end };
    }
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, () => initialState);
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { openModSelfPlan, closeModal, selectEvent } = slice.actions;

// ----------------------------------------------------------------------

export function getSelfPlans() {
  return async (dispatch) => {
    let accessToken = window.localStorage.getItem('accessToken');
    dispatch(slice.actions.startLoading());
    accessToken = await checkTokenAndRefresh(dispatch);
    try {
      const url = `${urls.user}/independence-plans/lists`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      console.log('response', response.data.data);
      dispatch(slice.actions.getSelfPlanSuccess(response.data.data));
    } catch (error) {
      console.log('error', error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function createSelfPlan(params) {
  return async (dispatch) => {
    let accessToken = window.localStorage.getItem('accessToken');
    dispatch(slice.actions.startLoading());
    accessToken = await checkTokenAndRefresh(dispatch);
    try {
      const url = `${urls.user}/independence-plans`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.post(url, body, config);
      console.log('createSelfPlan response', response.data.data);
      dispatch(slice.actions.createSelfPlanSuccess(response.data.data));
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}
// ----------------------------------------------------------------------

export function selectSelfPlanSuccess(plandId) {
  return async (dispatch) => {
    let accessToken = window.localStorage.getItem('accessToken');
    dispatch(slice.actions.startLoading());
    accessToken = await checkTokenAndRefresh(dispatch);
    try {
      const url = `${urls.user}/independence-plans/${plandId}`;
      const config = { headers: headers(accessToken) };
      const response = await axios.get(url, config);
      console.log('res', response.data.data);
      dispatch(slice.actions.selectSelfPlanSuccess(response.data.data));
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateSelfPlan(planId, params) {
  return async (dispatch) => {
    let accessToken = window.localStorage.getItem('accessToken');
    dispatch(slice.actions.startLoading());
    accessToken = await checkTokenAndRefresh(dispatch);
    try {
      const url = `${urls.user}/independence-plans/${planId}`;
      const body = cleanObject(params);
      const config = { headers: headers(accessToken) };
      const response = await axios.patch(url, body, config);
      console.log('response', response.data.data);
      dispatch(slice.actions.updateSelfPlanSuccess(response.data.data));
      dispatch(getSelfPlans());
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}

// ----------------------------------------------------------------------
export function deleteSelfPlan(planId) {
  return async (dispatch) => {
    let accessToken = window.localStorage.getItem('accessToken');
    dispatch(slice.actions.startLoading());
    accessToken = await checkTokenAndRefresh(dispatch);
    try {
      const url = `${urls.user}/independence-plans/${planId}`;
      const config = { headers: headers(accessToken) };
      await axios.delete(url, config);
      dispatch(slice.actions.deleteSelfPlanSuccess(planId));
      dispatch(getSelfPlans());
      return 'success';
    } catch (error) {
      console.log('error', error.response);
      dispatch(slice.actions.hasError(error));
      dispatch(getOpen(error));
    }
  };
}
// ----------------------------------------------------------------------

export function selectSelfPlan(start, end) {
  return async (dispatch) => {
    dispatch(
      slice.actions.selectRange({
        start: start.getTime(),
        end: end.getTime()
      })
    );
  };
}
