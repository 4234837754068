// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: 'Jalnan';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff')
    format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Godo';
  font-style: normal;
  font-weight: 700;
  src: url('https://cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoB.woff2')
      format('woff2'),
    url('https://cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoB.woff')
      format('woff');
}

.yg {
  font-family: 'Jalnan';
}

.godo {
  font-family: 'Godo'; /* 'Godo' 폰트를 .yg 클래스에 적용, 폰트를 찾지 못할 경우 sans-serif를 대체로 사용 */
}
`, "",{"version":3,"sources":["webpack://./src/font.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;EACrB;kBACgB;EAChB,mBAAmB;EACnB,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;EAChB;;;oBAGkB;AACpB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB,EAAE,4DAA4D;AACnF","sourcesContent":["@font-face {\n  font-family: 'Jalnan';\n  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff')\n    format('woff');\n  font-weight: normal;\n  font-style: normal;\n  font-display: swap;\n}\n\n@font-face {\n  font-family: 'Godo';\n  font-style: normal;\n  font-weight: 700;\n  src: url('https://cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoB.woff2')\n      format('woff2'),\n    url('https://cdn.jsdelivr.net/korean-webfonts/1/corps/godo/Godo/GodoB.woff')\n      format('woff');\n}\n\n.yg {\n  font-family: 'Jalnan';\n}\n\n.godo {\n  font-family: 'Godo'; /* 'Godo' 폰트를 .yg 클래스에 적용, 폰트를 찾지 못할 경우 sans-serif를 대체로 사용 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
