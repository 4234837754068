import React from 'react';
import { TopBox } from './Styled';
import star from '../asset/img/ic/star.webp';
import arrow from '../asset/img/ic/arrow.svg';
import empty from '../asset/img/mentor_empty.svg';
import mentorIcon from '../asset/img/ic/mentor_icon.webp';
import { styled } from 'styled-components';
import { useNavigate } from 'react-router-dom';
const MentorTag = styled.div`
  display: flex;
  padding: 5px;
  height: 18px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: ${(props) => props.color || '#0054f7'};
  color: #fff;
  font-size: 12px;
`;
const Mentor = ({ data }) => {
  const { introImage, nickname, memo, category, isMyMentor, isMyTeacher } =
    data;

  const navigate = useNavigate();
  return (
    <>
      {isMyMentor || isMyTeacher ? (
        <TopBox
          className="flex flex-row gap-2 mb-3"
          style={{ padding: '10px' }}
          onClick={() =>
            navigate(`/mentorDetail/${data?._id}`, { state: data })
          }>
          <div className="w-80 h-80 ">
            <img
              className="rounded-md"
              src={introImage?.url || empty}
              alt="멘토이미지"
            />
          </div>
          <div className="width-[66%]">
            <div className="flex flex-row items-center gap-1">
              {isMyMentor && (
                <MentorTag color="#d070fb">
                  <div className="w-8 mr-0.5">
                    <img src={mentorIcon} alt="별" />
                  </div>

                  <div>멘토</div>
                </MentorTag>
              )}
              {isMyTeacher && (
                <MentorTag>
                  <div>
                    <img src={star} alt="별" />
                  </div>

                  <div>담당</div>
                </MentorTag>
              )}

              <div className="text-16 text-text1 font-bold">{nickname}</div>
            </div>

            <div className="text-14 text-text1">{category?.title}</div>
            <div className="flex flex-row justify-between mt-[13px]">
              <div className="text-12 text-text5 mt-[3px] overflow-hidden text-ellipsis whitespace-nowrap w-[100px]">
                {memo}
              </div>
              <div className="absolute right-[45px]">
                <img src={arrow} alt="화살표" />
              </div>
            </div>
          </div>
        </TopBox>
      ) : (
        <TopBox
          className="flex flex-row gap-2 mb-3"
          style={{ padding: '10px' }}
          onClick={() =>
            navigate(`/mentorDetail/${data?._id}`, { state: data })
          }>
          <div className="w-80 h-80 ">
            <img
              className="rounded-md"
              src={introImage?.url || empty}
              alt="멘토"
            />
          </div>
          <div className="width-[66%]">
            <div className="text-16 text-text1 font-bold">{nickname}</div>

            <div className="text-14 text-text1">{category?.title}</div>
            <div className="flex flex-row justify-between mt-[13px]">
              <div className="text-12 text-text5 mt-[3px] overflow-hidden text-ellipsis whitespace-nowrap w-[100px]">
                {memo}
              </div>
              <div
                className="absolute right-[45px]"
                onClick={() => navigate('/mentorDetail')}>
                <img src={arrow} alt="화살표" />
              </div>
            </div>
          </div>
        </TopBox>
      )}
    </>
  );
};

export default Mentor;
