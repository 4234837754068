import { getRefreshToken } from '../store/slices/user';
import { isValidToken } from './jwt';

// 두 날짜의 차를 시간단위로 반환
export const getTimeDifference = (startAt, endAt) => {
  const startDate = startAt instanceof Date ? startAt : new Date(startAt);
  const endDate = endAt instanceof Date ? endAt : new Date(endAt);

  let formatted = ((endDate - startDate) / (1000 * 60 * 60)).toFixed(1);

  if (formatted.endsWith('0')) {
    formatted = formatted.substring(0, formatted.length - 2);
  }

  return formatted;
};

// 함수 정의
export const checkTokenAndRefresh = async (dispatch) => {
  let accessToken = window.localStorage.getItem('accessToken');
  const result = isValidToken(accessToken);
  if (!result) {
    accessToken = await dispatch(getRefreshToken());
    return accessToken;
  }
  return accessToken;
};

// 익명함수 정의
export const checkAnonymousTokenAndRefresh = async (dispatch) => {
  let anonymousToken = window.localStorage.getItem('anonymousToken');
  const result = isValidToken(anonymousToken);
  if (!result) {
    anonymousToken = await dispatch(getRefreshToken());
    return anonymousToken;
  }
  return anonymousToken;
};

export const isDate = (date) => {
  const convertedDate = new Date(date);
  return !isNaN(convertedDate);
};

// 0 제거
export const removeLeadingZero = (data = '') => {
  const result = parseInt(data, 10);
  return result.toString();
};

// isAdminPriority 먼저 그다음isFavorite가 true인 요소를 먼저 배치하는 비교 함수
export function compareFavorites(item1, item2) {
  if (item1.isAdminPriority && !item2.isAdminPriority) {
    return -1; // item1을 먼저 배치
  } else if (!item1.isAdminPriority && item2.isAdminPriority) {
    return 1; // item2를 먼저 배치
  } else if (item1.isFavorite && !item2.isFavorite) {
    return -1; // item1을 먼저 배치
  } else if (!item1.isFavorite && item2.isFavorite) {
    return 1; // item2를 먼저 배치
  } else {
    return 0; // 순서 변경 없음
  }
}

// startAt이 오늘 날짜와 시간보다 빠른지 확인하는 함수
export function isStartAtCheck(startAt) {
  const today = new Date();
  const startAtDate = new Date(startAt);

  // 날짜만 비교
  const todayDate = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate()
  );
  const startAtOnlyDate = new Date(
    startAtDate.getFullYear(),
    startAtDate.getMonth(),
    startAtDate.getDate()
  );

  // 시간까지 비교
  return (
    startAtOnlyDate.getTime() < todayDate.getTime() ||
    (startAtOnlyDate.getTime() === todayDate.getTime() &&
      startAtDate.getTime() < today.getTime())
  );
}
