import React, { useEffect } from 'react';
import { styled } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { getAnonymousToken } from '../store/slices/user';
import { isEmpty } from 'lodash';
import { useNavigate } from 'react-router-dom';
import back from '../asset/img/back.png';
import ilharulogo from '../asset/img/ilharulogo.svg';
import { getVisit } from '../store/slices/ilharu';
import { bool, boolean } from 'yup';

const Button = styled.button`
  font-family: Godo;
  width: 228px;
  height: 53px;
  border-radius: 10px;
  background: #8082c0;
  box-shadow: 4px 4px 4px 0px rgba(0, 0, 0, 0.25),
    2px 4px 4px 0px rgba(255, 255, 255, 0.25) inset,
    -2px -2px 4px 0px rgba(255, 255, 255, 0.25) inset;
  color: #fff;
  text-align: center;
  font-size: 22px;
  line-height: normal;
  letter-spacing: 2.64px;
  &:hover {
    background: #686ab8;
  }
  &:active {
    background: #686ab8;
  }
`;

const Position = styled.div`
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  outline: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const anonymousToken = localStorage.getItem('anonymousToken');
  const hasVisited = localStorage.getItem('hasVisited');
  console.log('hasVisited', hasVisited);
  useEffect(() => {
    if (!hasVisited) {
      dispatch(getVisit());
    }

    if (isEmpty(anonymousToken)) {
      dispatch(getAnonymousToken());
    }
  }, []);

  return (
    <div
      className="w-full h-screen"
      style={{
        backgroundImage: `url(${back})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: ' center center'
      }}>
      <Position>
        <img width={'100%'} src={ilharulogo} alt="로고" />

        <div className="flex flex-col gap-4 mt-[94px] items-center">
          <Button type="submit" onClick={() => navigate('/login')}>
            입장하기
          </Button>

          <Button type="submit" onClick={() => navigate('/introduce')}>
            일하루 소개
          </Button>
          <Button type="submit" onClick={() => navigate('/survey')}>
            설문
          </Button>
        </div>
      </Position>
    </div>
  );
};

export default Home;
