import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { isEmpty } from 'lodash';
import { useCRUD } from '../hooks/useCRUD';
import {
  createJournal,
  deleteJournal,
  updateJournal
} from '../store/slices/schedule';
import { getOpenAlert } from '../store/slices/modal';
import { fDate } from '../utils/formatTime';
//  토스트
import { toast, ToastContainer } from 'react-toastify';

// 컴포넌트
import BasicModal from '../component/Modal';
import {
  BlueBox,
  Button,
  Card,
  LayoutBgGray,
  Paper
} from '../component/Styled';
import BackHeader from '../component/BackHeader';
import DateTimePicker from '../component/DateTimePicker';
import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';
import './date.css';

const JournalWrite = () => {
  const today = new Date();
  const rememberDate = new Date(localStorage.getItem('rememberDate') || today);

  const { state } = useLocation();

  const location = useLocation();
  const { data, mentor } = state;
  const { error } = useSelector((state) => state.schedule);
  const { handleUpdate, handleDelete } = useCRUD();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const deleteData = () => {
    if (state?.journal?.comment) {
      return dispatch(getOpenAlert('담당 선생님께 문의해주세요'));
    }
    const res = handleDelete(deleteJournal, state?.journal?._id);
    if (res) {
      toast('삭제 성공');
      setTimeout(() => navigate(-1), 1000);
    }
  };

  const writeSchema = Yup.object().shape({
    content: Yup.string().required('내용을 적어주세요.'),
    feelings: Yup.string().required('느낀점을 적어주세요.'),
    startAt: Yup.date().required('시작 날짜를 입력해주세요'),
    endAt: Yup.date().required('종료 날짜를 입력해주세요')
  });

  const formik = useFormik({
    validationSchema: writeSchema,
    writeSchema,
    initialValues: {
      content: '' || state?.journal?.content,
      feelings: '' || state?.journal?.feelings,
      startAt: state?.journal?.content
        ? state?.journal?.startAt
        : new Date(rememberDate.setHours(9, 0, 0, 0)),
      endAt: state?.journal?.content
        ? state?.journal?.endAt
        : new Date(rememberDate.setMinutes(30))
    },
    onSubmit: async (values, { setErrors }) => {
      let res;
      if (location?.pathname === '/journalWrite') {
        // 일정 아이디
        res = await handleUpdate(createJournal, state?.data?._id, values);

        if (res) {
          toast('작성 성공');
          setTimeout(() => navigate(-1), 1000);
        }
      } else {
        // 활동일지 아이디
        res = await handleUpdate(updateJournal, state?.journal?._id, values);
        if (res) {
          toast('수정 성공');
          setTimeout(() => navigate(-1), 1000);
        }
      }

      try {
      } catch (error) {
        const { response } = error;
        const afterSubmit = response.data.response.message;
        setErrors({ afterSubmit });
      }
    }
  });

  const {
    errors,
    touched,
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue
  } = formik;
  console.log('values', values);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <LayoutBgGray>
          <BasicModal msg={error?.response?.message} />
          <ToastContainer
            position="top-right" // 알람 위치 지정
            autoClose={500} // 자동 off 시
          />
          <>
            <BackHeader ment={'활동일지 작성'} main="true" />
            {!isEmpty(state?.journal?.comment) && (
              <BlueBox
                className="mx-auto flex flex-col "
                style={{ cursor: 'default' }}>
                <div className="text-13 text-text2">담당선생님 추가예정</div>
                <p className="text-14 text-text3 mt-3 whitespace-pre-wrap">
                  {state?.journal?.comment?.content}
                </p>
              </BlueBox>
            )}

            <Card className="px-4 py-4">
              <div className="flex pt-2 flex-col items-start self-stretch  border-b border-solid border-[#e4e4e4]">
                <div className="text-text1 text-15 font-semibold">
                  {data?.category?.title}
                </div>
                <div className="text-text1 text-20 font-bold">
                  {data?.title}
                </div>
                <div className="text-text1 font-20">{mentor?.nickname}</div>

                <div className="text-14 text-text3 mt-2">
                  시작시간 - {fDate(data?.startDate)}
                </div>
                <div className="text-14 text-text3 mb-2">
                  종료시간 - {fDate(data?.endDate)}
                </div>
              </div>
              <div className="text-text1 text-15 font-medium relative top-[15px]">
                근무시간
              </div>
              <DateTimePicker
                key="시작일"
                label="시작일"
                value={values?.startAt}
                errorLabel="startAt"
                errors={errors}
                touched={touched}
                onChange={(dateStr) => {
                  const date = new Date(dateStr);
                  const endAt = new Date(values?.endAt);
                  if (date > endAt) {
                    setFieldValue('endAt', date);
                  }
                  setFieldValue('startAt', date);
                }}
              />
              <DateTimePicker
                key="종료일"
                label="종료일"
                minDateTime={values?.startAt}
                value={values?.endAt}
                errorLabel="endAt"
                errors={errors}
                touched={touched}
                onChange={(date) => setFieldValue('endAt', date)}
              />

              <div className="text-text1 text-15 font-medium mt-8 mb-1">
                근무내용
              </div>
              <Paper
                placeholder={`근무내용 작성`}
                {...getFieldProps('content')}
                error={Boolean(touched.content && errors.content)}
              />
              <div className="text-error text-13">
                {touched.content && errors.content}
              </div>
              <div className="text-text1 text-15 font-medium mt-4 mb-1">
                느낀 점
              </div>
              <Paper
                placeholder={`느낀 점`}
                {...getFieldProps('feelings')}
                error={Boolean(touched.feelings && errors.feelings)}
              />
              <div className="text-error text-13">
                {touched.feelings && errors.feelings}
              </div>
            </Card>
            <div className="text-center">
              <Button
                type="submit"
                style={{ height: '56px' }}
                color="#0054F7"
                className="text-main2 mt-6 mb-4">
                저장
              </Button>
              {state?.journal?.content && (
                <div
                  className="underline text-14 text-text11 text-center pb-10 cursor-pointer"
                  onClick={deleteData}>
                  활동일지 삭제
                </div>
              )}
            </div>
          </>
        </LayoutBgGray>
      </Form>
    </FormikProvider>
  );
};

export default JournalWrite;
