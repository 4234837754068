import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getClose } from '../store/slices/modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 300,
  bgcolor: 'background.paper',
  border: '2px solid #fff',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
  borderRadius: '10px'
};

export default function BasicModal({ title = '오류내용' }) {
  const { isOpen, msg } = useSelector((state) => state.modal);
  const dispatch = useDispatch();
  const handleClose = () => {
    return dispatch(getClose());
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Typography variant="body1" textAlign={'left'}>
            {title}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 4, mb: 2 }}>
            {msg}
          </Typography>
          <Button onClick={handleClose}>확인</Button>
        </Box>
      </Modal>
    </div>
  );
}
