import React from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { isEmpty } from 'lodash';
import {
  createReview,
  deleteWrite,
  updateReview
} from '../store/slices/schedule';
import { useCRUD } from '../hooks/useCRUD';
import { fDate } from '../utils/formatTime';

import { styled } from 'styled-components';
import BackHeader from '../component/BackHeader';
import { BlueBox, Button, Card, LayoutBgGray } from '../component/Styled';
import BasicModal from '../component/Modal';
//  토스트
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Paper = styled.textarea`
  display: flex;
  width: 100%;
  height: 150px;
  padding: 12px;
  border-radius: 10px;
  border: 1px solid #e4e4e4;
  background: #fff;
`;

const ReviewWrite = () => {
  const { state } = useLocation();
  const { error } = useSelector((state) => state.schedule);
  const { handleUpdate, handleDelete } = useCRUD();
  const navigate = useNavigate();
  console.log('state', state);
  const deleteData = () => {
    const res = handleDelete(deleteWrite, state?.review?._id);
    if (res) {
      toast('삭제 성공');
      setTimeout(() => navigate(-1), 1000);
    }
  };

  const writeSchema = Yup.object().shape({
    content: Yup.string().required('후기를 적어주세요.')
  });
  const formik = useFormik({
    validationSchema: writeSchema,
    writeSchema,
    initialValues: {
      content: '' || state?.review?.content,
      writeAt: fDate(new Date()) || state?.review?.writeAt
    },
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      let res;
      if (isEmpty(state?.review)) {
        res = await handleUpdate(
          createReview,
          state?.activities[0]?._id,
          values
        );
      } else {
        res = await handleUpdate(updateReview, state?.review?._id, values);
      }
      if (res) {
        toast('작성 성공');
        setTimeout(() => navigate(-1), 1000);
      }
      try {
      } catch (error) {
        const { response } = error;
        const afterSubmit = response.data.response.message;
        setErrors({ afterSubmit });
      }
    }
  });
  const { errors, touched, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <LayoutBgGray>
          <BasicModal msg={error?.response?.message} />
          <ToastContainer
            position="top-right" // 알람 위치 지정
            autoClose={500} // 자동 off 시
          />
          <>
            <BackHeader ment={'소감/후기 작성'} main="true" />
            {!isEmpty(state?.review?.comment) && (
              <BlueBox
                className="mx-auto flex flex-col"
                style={{ cursor: 'default' }}>
                <div className="text-13 text-text2">
                  담당멘토 {state?.mentor?.nickname}
                </div>
                <p className="text-14 text-text3 mt-3 whitespace-pre-wrap">
                  {state?.review?.comment?.content}
                </p>
              </BlueBox>
            )}
            <Card className="px-4 py-4">
              <div className="flex pt-2 flex-col items-start self-stretch  border-b border-solid border-[#e4e4e4] mb-5">
                <div className="text-text1 text-15 font-semibold">
                  {state?.data?.category?.title}
                </div>
                <div className="text-text1 text-20 font-bold">
                  {state?.data?.title}
                </div>
                <div className="text-text1 font-20">
                  {state?.mentor?.nickname}
                </div>

                <div className="text-14 text-text3 mt-2">
                  시작시간 - {fDate(state?.data?.startDate)}
                </div>
                <div className="text-14 text-text3 mb-2">
                  종료시간 - {fDate(state?.data?.endDate)}
                </div>
              </div>
              <Paper
                placeholder={`해당 일정의 개인적인 소감과 후기를 자유롭게 작성해주세요 :)\n\n다른 친구들과 선생님들에게 힘이 될 수 있도록 길게 작성할 수록 더 좋아요!`}
                {...getFieldProps('content')}
                error={Boolean(touched.content && errors.content)}
              />
              <div className="text-error text-13">
                {touched.content && errors.content}
              </div>
            </Card>
            <div className="text-center">
              <Button
                color="#0054F7"
                style={{ height: '56px' }}
                className="text-main2 mt-6 mb-4"
                type="submit">
                저장
              </Button>
              {state?.review?.content && (
                <div
                  className="underline text-14 text-text11 text-center pb-10 cursor-pointer tracking-[-0.5px]"
                  onClick={deleteData}>
                  소감/후기 삭제
                </div>
              )}
            </div>
          </>
        </LayoutBgGray>
      </Form>
    </FormikProvider>
  );
};

export default ReviewWrite;
